export default class {
    static projectName = "The Beyond Life Style";
    static userData = "USER_DATA";
    static themeColor = '#fe6303';
    static textColor = '#ffffff'
    static emailRjx = /\S+@\S+\.\S+/;
    // static API_URL = "http://192.168.1.148:8084";
    // static API_URL = "http://192.168.1.12:8085";
    // static API_URL = "http://3.6.215.214:8085";
    static API_URL = "https://api.thebeyondlifestyle.com";
}