import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, CardTitle, Label } from 'reactstrap';
import ReactApexChart from "react-apexcharts"
import { Link } from "react-router-dom"
import moment from 'moment/moment';

const EarningChart = ({ data }) => {
    const [values, setValues] = useState([]);
    const [cats, setCats] = useState([]);

    useEffect(() => {
        if (data?.length) {
            let tempVal = [], tempCat = [];
            data?.forEach((element) => {
                tempCat.push(element?.title);
                tempVal.push(element?.views);
            });
            setCats(tempCat);
            setValues(tempVal);
        }
    }, [data]);

    // console.log("data on earning chart", data);

    const options = {
        chart: {
            toolbar: {
                show: !1
            },
        }
        ,
        plotOptions: {
            bar: {
                horizontal: !0, barHeight: '24%', endingShape: 'rounded',
            }
        }
        ,
        dataLabels: {
            enabled: !1
        },
        colors: ['#556ee6'],
        xaxis: {
            categories: cats,
            title: {
                text: 'Users'
            }
            ,
        },
        yaxis: {
            labels: {
                style: {
                    fontSize: '12px',
                    colors: '#000',
                },
                maxWidth: 500,
            },
        },
    }

    const series = [{
        name: "Users",
        data: values
    }
    ]

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <CardTitle className="h4 mb-4">Podcast Watched</CardTitle>
                    <ReactApexChart
                        options={options}
                        series={series}
                        type="bar"
                        height="765"
                        className="apex-charts"
                    />
                </CardBody>
            </Card>
        </React.Fragment>
    )
}

export default EarningChart;