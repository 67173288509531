import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    CardTitle,
    CardSubtitle,
    Modal,
    ModalBody,
} from "reactstrap";
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import Breadcrumb from "../../components/Common/Breadcrumb";
import Utils from '../Utility'
import Loader from "../../components/Loader";
import DataTable from 'react-data-table-component';
import { download, post } from "../../helpers/api_helper";
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import { AvCheckbox, AvCheckboxGroup, AvField, AvForm } from "availity-reactstrap-validation";

const AllUsers = props => {
    const { user } = props;
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [currentData, setCurrentData] = useState(null);
    const [profileModal, setProfileModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(100);
    const [totalPage, setTotalPage] = useState(0);
    const [search, setSearch] = useState("");
    const [searchModal, setSearchModal] = useState(false);
    const [selectedDate, setSelectedDate] = useState("");
    const [dateTo, setDateTo] = useState(null);
    const [dateFrom, setDateFrom] = useState(null);
    const [rDateTo, setRDateTo] = useState(null);
    const [rDateFrom, setRDateFrom] = useState(null);
    const [pDateTo, setPDateTo] = useState(null);
    const [pDateFrom, setPDateFrom] = useState(null);
    const [sort, setSort] = useState({ createdAt: -1 });
    const [searchApplied, setSearchApplied] = useState(false);
    const [payStatus, setPaymentStatus] = useState([]);
    const [currentDate, setCurrentDate] = useState(() => {
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    });
    // console.log('props on company', props);

    useEffect(() => {
        if (user?.token) {
            getData();
        }
    }, [user, search, selectedDate, page, perPage, sort]);

    const getData = () => {
        if (!loading) {
            setLoading(true);
            let body = {
                search,
                page,
                size: perPage,
                sort,
                dateTo,
                dateFrom,
                rDateFrom,
                rDateTo,
                pDateFrom,
                pDateTo,
                payStatus,
                token: user?.token
            }
            post("/user/all", body)
                .then(json => {
                    setLoading(false);
                    // console.log('response from get project list', json);
                    if (json?.statusCode == 200) {
                        setData(json?.data);
                        setTotalPage(json?.totalPage);
                        setSearchApplied(false);
                    } else {
                        setData([]);
                        toast.error(json?.error);
                    }
                })
                .catch(error => {
                    setData([]);
                    setLoading(false);
                    console.log('error while getting project list', error);
                });
        }
    }

    useEffect(() => {
        setColumns([
            {
                name: 'S.N',
                selector: row => row?._id,
                cell: (row, index) => (page * 100) + (index + 1),
                maxWidth: "100px"
            },
            {
                name: <span onClick={() => {
                    const newSortOrder = sort.name === 1 ? -1 : 1;
                    setSort({ name: newSortOrder });
                }}>Name</span>,
                selector: row => row?.name,
                cell: (row) =>
                    <span style={{ textTransform: 'capitalize', marginLeft: 5 }}>{row?.name}</span>,
                sortable: true,
                sortFunction: (rows, field, direction) => {
                    return rows;
                },
                maxWidth: '195px'
            },
            {
                name: <span onClick={() => {
                    const newSortOrder = sort.email === 1 ? -1 : 1;
                    setSort({ email: newSortOrder });
                }}>Email</span>,
                selector: row => row?.email,
                sortFunction: (rows, field, direction) => {
                    return rows;
                },
                sortable: true
            },
            {
                name: <span onClick={() => {
                    const newSortOrder = sort.country === 1 ? -1 : 1;
                    setSort({ country: newSortOrder });
                }}>Country</span>,
                selector: row => row?.country == "Select Country" ? "" : row?.country,
                sortFunction: (rows, field, direction) => {
                    return rows;
                },
                sortable: true
            },
            {
                name: <span onClick={() => {
                    const newSortOrder = sort.createdAt === -1 ? 1 : -1;
                    setSort({ createdAt: newSortOrder });
                }}>Installed On</span>,
                selector: row => moment(row?.createdAt).format("DD MMM, YYYY hh:mm a"),
                sortFunction: (rows, field, direction) => {
                    return rows;
                },
                maxWidth: "180px"
            },
            {
                name: <span onClick={() => {
                    const newSortOrder = sort.registeredOn === -1 ? 1 : -1;
                    setSort({ registeredOn: newSortOrder });
                }}>Registered On</span>,
                selector: row => row?.registeredOn,
                cell: (row) => <>
                    <span style={{ textTransform: 'capitalize' }}>{row?.registeredOn ? moment.tz(row?.registeredOn, 'Asia/Kolkata').format("DD MMM YYYY, hh:mm A") : "N/A"}</span>
                </>,
                sortable: true,
                sortFunction: (rows, field, direction) => {
                    return rows;
                },
                maxWidth: '170px'
            },
            {
                name: <span onClick={() => {
                    const newSortOrder = sort["trans.createdAt"] === -1 ? 1 : -1;
                    setSort({ "trans.createdAt": newSortOrder });
                }}>Subscribed On</span>,
                selector: row => row?.trans ? row?.trans?.createdAt : "",
                cell: (row) => <>
                    <span style={{ textTransform: 'capitalize' }}>{row?.trans ? moment.tz(row?.trans?.createdAt, 'Asia/Kolkata').format("DD MMM YYYY, hh:mm A") : "N/A"}</span>
                </>,
                sortable: true,
                sortFunction: (rows, field, direction) => {
                    return rows;
                },
                maxWidth: '170px'
            },
            {
                name: <span onClick={() => {
                    const newSortOrder = sort["trans.status"] === -1 ? 1 : -1;
                    setSort({ "trans.status": newSortOrder });
                }}>₹ Status</span>,
                selector: row => row?.trans ? row?.trans?.status : "-",
                cell: (row) => <>
                    {row?.trans ?
                        <>
                            {row?.trans?.status == "Success" ?
                                <Button
                                    title={"Sucess"}
                                    style={{ backgroundColor: 'green', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className={"mdi mdi-rupee-sign"} style={{ color: 'white' }}>Success</span></Button>
                                : null}
                            {row?.trans?.status == "Pending" ?
                                <Button
                                    title={"Pending"}
                                    style={{ backgroundColor: 'yellow', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className={"mdi mdi-rupee-sign"} style={{ color: 'black' }}>Pending</span></Button>
                                : null}
                            {row?.trans?.status == "Cancelled" ?
                                <Button
                                    title={"Cancelled"}
                                    style={{ backgroundColor: 'red', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className={"mdi mdi-rupee-sign"} style={{ color: 'white' }}>Cancelled</span></Button>
                                : null}
                            {row?.trans?.status == "Failed" ?
                                <Button
                                    title={"Failed"}
                                    style={{ backgroundColor: 'red', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className={"mdi mdi-rupee-sign"} style={{ color: 'white' }}>Failed</span></Button>
                                : null}
                        </>
                        :
                        <span style={{ textTransform: 'capitalize' }}>-</span>
                    }
                </>,
                sortable: true,
                sortFunction: (rows, field, direction) => {
                    return rows;
                },
                maxWidth: '125px'
            },
            {
                name: <span onClick={() => {
                    const newSortOrder = sort["trans.planId"] === -1 ? 1 : -1;
                    setSort({ "trans.planId": newSortOrder });
                }}>Plan</span>,
                selector: row => row?.trans ? row?.trans?.image : "-",
                cell: (row) => <>
                    {row?.trans ?
                        <>
                            <img src={row?.trans?.image} style={{ width: 25, height: 25 }} />
                        </>
                        :
                        <span style={{ textTransform: 'capitalize' }}>None</span>
                    }
                </>,
                maxWidth: "100px",
                sortable: true,
                sortFunction: (rows, field, direction) => {
                    return rows;
                },
            },
            {
                name: <span onClick={() => {
                    const newSortOrder = sort.deviceType === -1 ? 1 : -1;
                    setSort({ deviceType: newSortOrder });
                }}>OS</span>,
                selector: row => row?.deviceType,
                cell: (row) => <div>
                    {row?.deviceType == 'android' ?
                        <img src={require("../../assets/images/android.png")} style={{ width: 25, height: 25 }} />
                        : null}
                    {row?.deviceType == 'web' ?
                        <img src={require("../../assets/images/web.png")} style={{ width: 25, height: 25 }} />
                        : null}
                    {row?.deviceType == 'ios' ?
                        <img src={require("../../assets/images/iOS.png")} style={{ width: 25, height: 25 }} />
                        : null}
                    {/* <i className={row?.deviceType == 'android' ? "mdi mdi-android" : row?.deviceType == 'web' ? "mdi mdi-web" : "mdi mdi-apple-ios"} style={{ fontSize: 18 }} /> */}
                </div>,
                sortable: true,
                sortFunction: (rows, field, direction) => {
                    return rows;
                },
                maxWidth: '100px'
            },
            {
                name: <span onClick={() => {
                    const newSortOrder = sort.lastSeen === -1 ? 1 : -1;
                    setSort({ lastSeen: newSortOrder });
                }}>Last Active</span>,
                selector: row => row?.lastSeen,
                cell: (row) => <>
                    <span style={{}}>{moment.tz(row?.lastSeen, 'Asia/Kolkata').parseZone('Asia/Kolkata').fromNow()}</span>
                </>,
                sortable: true,
                sortFunction: (rows, field, direction) => {
                    return rows;
                },
                maxWidth: '135px'
            },
            {
                name: <span onClick={() => {
                    const newSortOrder = sort["trans.status"] === -1 ? 1 : -1;
                    setSort({ "trans.status": newSortOrder });
                }}>Last Txn</span>,
                selector: row => row?.trans ? row?.trans?.status : "-",
                cell: (row) => <>
                    {row?.trans ?
                        <>
                            {row?.trans?.status == "Success" ?
                                <Button className="status_btn"
                                    title={"Sucess"}
                                    style={{ backgroundColor: 'green', border: 'none' }}><span className={"mdi mdi-rupee-sign"} style={{ color: 'white' }}>Success</span></Button>
                                : null}
                            {row?.trans?.status == "Pending" ?
                                <Button className="status_btn"
                                    title={"Pending"}
                                    style={{ backgroundColor: 'yellow', border: 'none' }}><span className={"mdi mdi-rupee-sign"} style={{ color: 'black' }}>Pending</span></Button>
                                : null}
                            {row?.trans?.status == "Cancelled" ?
                                <Button className="status_btn"
                                    title={"Cancelled"}
                                    style={{ backgroundColor: 'red', border: 'none' }}><span className={"mdi mdi-rupee-sign"} style={{ color: 'white' }}>Cancelled</span></Button>
                                : null}
                            {!row?.trans?.status ?
                                <Button className="status_btn"
                                    title={"Cancelled"}
                                    style={{ backgroundColor: 'red', border: 'none' }}><span className={"mdi mdi-rupee-sign"} style={{ color: 'white' }}>Cancelled</span></Button>
                                : null}
                        </>
                        :
                        <span style={{ textTransform: 'capitalize' }}>N/A</span>
                    }
                </>,
                sortable: true,
                sortFunction: (rows, field, direction) => {
                    return rows;
                },
                maxWidth: '125px'
            },
            {
                name: <span onClick={() => {
                    const newSortOrder = sort["deviceToken"] === -1 ? 1 : -1;
                    setSort({ "deviceToken": newSortOrder });
                }}>Action</span>,
                cell: (row) => <>
                    <>
                        <Button className="table_action_btn"
                            title={"Analytic"}
                            style={{ backgroundColor: Utils.themeColor, border: 'none' }}><Link target="_blank" to={row?._id ? "/anayltic/user/" + row?._id : ""}><span className={"mdi mdi-finance"} style={{ color: Utils.textColor }}></span></Link></Button>
                        <Button className="table_action_btn"
                            onClick={() => { setCurrentData(row); setProfileModal(true) }}
                            title={"View"}
                            style={{ backgroundColor: Utils.themeColor, border: 'none' }}><span className={"mdi mdi-eye"} style={{ fontSize: 14, color: 'white' }}></span></Button>
                        {row?.deviceToken &&
                            <Button className="table_action_btn" onClick={() => handleSendNoti(row)}
                                title={"Send Notification"}
                                style={{ backgroundColor: Utils.themeColor, border: 'none' }}><span className={"mdi mdi-bell"} style={{ color: Utils.textColor }}></span></Button>
                        }
                        {user?.role == "Admin" ?
                            <Button className="table_action_btn" onClick={() => handleDeleteProj(row)}
                                title={row?.isBanned ? "Allow" : "Ban"}
                                style={{ backgroundColor: row?.isBanned ? 'red' : Utils.themeColor, border: 'none' }}><span className={row?.isBanned ? "mdi mdi-account-alert" : "mdi mdi-cancel"} style={{ color: Utils.textColor }}></span></Button>
                            :
                            <>
                                {user?.permissions?.length && user?.permissions[0]?.user?.delete &&
                                    <Button className="table_action_btn" onClick={() => handleDeleteProj(row)}
                                        title={row?.isBanned ? "Allow" : "Ban"}
                                        style={{ backgroundColor: row?.isBanned ? 'red' : Utils.themeColor, border: 'none' }}><span className={row?.isBanned ? "mdi mdi-account-alert" : "mdi mdi-cancel"} style={{ color: Utils.textColor }}></span></Button>
                                }
                            </>
                        }
                    </>
                </>,
                sortFunction: (rows, field, direction) => {
                    return rows;
                }, maxWidth: '190px'
            }
        ]);
    }, [page, perPage, data]);

    const handleDownloadCsv = () => {
        if (totalPage < 1)
            return;
        if (!loading) {
            setLoading(true);
            let body = {
                search,
                page,
                size: perPage,
                sort,
                dateTo,
                dateFrom,
                rDateFrom,
                rDateTo,
                pDateFrom,
                pDateTo,
                payStatus,
                token: user?.token
            }
            download("/user/download/all", body)
                .then(blob => {
                    setLoading(false);
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = 'all_users.csv';
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                })
                .catch(error => {
                    setLoading(false);
                    console.log('error while getting project list', error);
                });
        }
    }

    const handleValidFilter = (e, v) => {
        if (!loading) {
            setLoading(true);
            let body = {
                ...v,
                search,
                page: 0,
                size: perPage,
                sort,
                payStatus,
                token: user?.token
            }
            post("/user/all", body)
                .then(json => {
                    setLoading(false);
                    // console.log('response from get project list', json);
                    if (json?.statusCode == 200) {
                        setData(json?.data);
                        setTotalPage(json?.totalPage);
                        // setDateFrom(v?.dateFrom);
                        setDateTo(v?.dateTo);
                        setRDateTo(v?.rDateTo);
                        setPDateTo(v?.pDateTo);
                        // setPaymentStatus(v?.payStatus)
                        setSearchModal(false);
                        setSearchApplied(true);
                    } else {
                        setData([]);
                        toast.error(json?.error);
                    }
                })
                .catch(error => {
                    setData([]);
                    setLoading(false);
                    console.log('error while getting project list', error);
                });
        }
    }

    const handleFilterStatus = (val) => {
        console.log("changing pay", val);
        let temp = [...payStatus];
        let found = temp.findIndex(x => x == val);
        if (found == -1) {
            temp.push(val);
        } else
            temp.splice(found, 1);
        console.log("changing paystatus", temp);
        setPaymentStatus(temp);
    }

    // console.log("paystatus", payStatus);

    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumb title={Utils.projectName} breadcrumbItem="All Users" />
                <Loader visible={loading} />
                {profileModal &&
                    <UserProfile
                        show={profileModal}
                        onCloseClick={() => setProfileModal(false)}
                        userId={currentData?._id}
                    />
                }
                <Modal className="modal_form" isOpen={searchModal} toggle={() => { setSearchModal(false); }} centered={true}>
                    <ModalBody className="py-3 px-5">
                        <h5 className="text-black font-size-20">Advance Search</h5>
                        <div className="p-2">
                            <AvForm
                                className="form-horizontal"
                                onValidSubmit={(e, v) => {
                                    handleValidFilter(e, v)
                                }}
                            >
                                <Row>
                                    <Col md={12}>
                                        <div className="form-group mb-4">
                                            <AvCheckboxGroup inline name="payStatus" label="Payment Status"
                                            >
                                                <AvCheckbox label="Success" value="Success" checked={payStatus.includes("Success")} onChange={(e) => handleFilterStatus(e.target.value)} />
                                                <AvCheckbox label="Pending" value="Pending" checked={payStatus.includes("Pending")} onChange={(e) => handleFilterStatus(e.target.value)} />
                                                <AvCheckbox label="Cancelled" value="Cancelled" checked={payStatus.includes("Cancelled")} onChange={(e) => handleFilterStatus(e.target.value)} />
                                                <AvCheckbox label="Failed" value="Failed" checked={payStatus.includes("Failed")} onChange={(e) => handleFilterStatus(e.target.value)} />
                                            </AvCheckboxGroup>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="mb-3 mt-2">
                                            <AvField
                                                name="dateFrom"
                                                label="Installed Date From"
                                                className="form-control"
                                                value={dateFrom}
                                                type="date"
                                                onChange={(e) => setDateFrom(e.target.value)}
                                                validate={{ max: { value: currentDate } }}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="mb-3 mt-2">
                                            <AvField
                                                name="dateTo"
                                                label="Installed Date To"
                                                className="form-control"
                                                // placeholder="Enter detailed notification"
                                                type="date"
                                                value={dateTo}
                                                required={!!dateFrom}
                                                validate={{ max: { value: currentDate } }}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="mb-3 mt-2">
                                            <AvField
                                                name="rDateFrom"
                                                label="Registered Date From"
                                                className="form-control"
                                                // placeholder="Enter something about your notification"
                                                type="date"
                                                value={rDateFrom}
                                                onChange={(e) => setRDateFrom(e.target.value)}
                                                validate={{ max: { value: currentDate } }}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="mb-3 mt-2">
                                            <AvField
                                                name="rDateTo"
                                                label="Registered Date To"
                                                className="form-control"
                                                // placeholder="Enter detailed notification"
                                                type="date"
                                                value={rDateTo}
                                                required={!!rDateFrom}
                                                validate={{ max: { value: currentDate } }}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="mb-3 mt-2">
                                            <AvField
                                                name="pDateFrom"
                                                label="Subscribed Date From"
                                                className="form-control"
                                                // placeholder="Enter something about your notification"
                                                type="date"
                                                value={pDateFrom}
                                                onChange={(e) => { setPDateFrom(e.target.value); setPaymentStatus(["Success"]) }}
                                                validate={{ max: { value: currentDate } }}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="mb-3 mt-2">
                                            <AvField
                                                name="pDateTo"
                                                label="Subscribed Date To"
                                                className="form-control"
                                                // placeholder="Enter detailed notification"
                                                type="date"
                                                value={pDateTo}
                                                required={!!pDateFrom}
                                                onChange={() => setPaymentStatus(["Success"])}
                                                validate={{ max: { value: currentDate } }}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={12} md={12}>
                                        <div className="mt-4">
                                            <Row>
                                                <Col md={6}>
                                                    <button
                                                        className="btn btn-primary w-100 waves-effect waves-light"
                                                        style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                                                        onClick={() => {
                                                            setSearchModal(false);
                                                            setDateFrom(null);
                                                            setRDateFrom(null);
                                                            setPDateFrom(null);
                                                            setDateTo(null);
                                                            setRDateTo(null);
                                                            setPDateTo(null);
                                                            setPaymentStatus([]);
                                                            setSort({ createdAt: -1 })
                                                        }}
                                                        type="reset"
                                                    >
                                                        Cancel
                                                    </button>
                                                </Col>
                                                <Col md={6}>
                                                    <button
                                                        className="btn btn-primary w-100 waves-effect waves-light"
                                                        type="submit"
                                                        style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                                                    >
                                                        Apply
                                                    </button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </AvForm>
                        </div>
                    </ModalBody>
                </Modal>
                <Card>
                    <CardBody>
                        <Row>
                            <Col md={6}>
                                <CardTitle className="h4">All Users</CardTitle>
                                <CardSubtitle className="mb-3">
                                    Users those who registered himself, All the <code>Users</code> will also be able see and <code>actions</code> on the <code>Properties.</code>
                                </CardSubtitle>
                            </Col>
                            <Col md={6}>
                                <div className="group_btn_box">
                                    <Button style={{ backgroundColor: Utils.themeColor, marginLeft: 5, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}
                                        onClick={handleDownloadCsv}>
                                        Export User ({totalPage})
                                    </Button>
                                    <Button
                                        onClick={() => setSearchModal(true)}
                                    >
                                        <span class="mdi mdi-filter"></span> Advanced Filter
                                    </Button>
                                    {/* {searchApplied ? */}
                                    {/* <Button
                                        onClick={() => { setNotiType("individual"); setNotiModal(true) }}
                                    >
                                        <span class="mdi mdi-bell"></span> Notify All
                                    </Button> */}
                                    {/* : null}  */}
                                </div>
                            </Col>
                            <Col md={12} className="mb-4">
                                <AvForm>
                                    <div>
                                        <AvField
                                            name="search"
                                            label="Search"
                                            placeholder="Search by user's Name, Email, mobile"
                                            type="text"
                                            value={search}
                                            onChange={(e) => setSearch(e.target.value)}
                                        >
                                        </AvField>
                                    </div>
                                </AvForm>
                            </Col>
                        </Row>
                        <DataTable
                            columns={columns}
                            data={data}
                            pagination
                            paginationPerPage={100}
                            paginationRowsPerPageOptions={[100, 200, 300, 400, 500]}
                            paginationServer
                            paginationTotalRows={totalPage}
                            onChangeRowsPerPage={(newPerPage, page) => setPerPage(newPerPage)}
                            onChangePage={(page) => setPage(page - 1)}
                            customStyles={{
                                headCells: {
                                    style: {
                                        color: 'black',
                                        fontWeight: 'bold',
                                        fontSize: 15,
                                        maxWidth: 80
                                    },
                                },
                                cells: {
                                    style: {
                                        maxWidth: 50
                                    }
                                }
                            }}
                        />
                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    )
}

const mapStatetoProps = state => {
    const { user } = state.Profile;
    return { user }
}

export default withRouter(
    connect(mapStatetoProps, {})(AllUsers)
)
