import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Row, Col, Collapse } from "reactstrap"
import { Link, withRouter } from "react-router-dom";
import { profileSuccess, editProfile, getProfile } from '../../store/auth/profile/actions';
//i18n
import { withTranslation } from "react-i18next"
import classname from "classnames"
import { connect } from "react-redux"

const Navbar = props => {
  const [dashboard, setdashboard] = useState(false)
  const [ui, setui] = useState(false)
  const [app, setapp] = useState(false)
  const [email, setemail] = useState(false)
  const [task, settask] = useState(false)
  const [component, setcomponent] = useState(false)
  const [form, setform] = useState(false)
  const [table, settable] = useState(false)
  const [chart, setchart] = useState(false)
  const [icon, seticon] = useState(false)
  const [map, setmap] = useState(false)
  const [extra, setextra] = useState(false);
  const { role, user } = props;

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    let user = await localStorage.getItem("authUser");
    if (user)
      user = JSON.parse(user);
    // console.log('user from storage', user);
    if (user) {
      props.getProfile({ user: user });
      // props?.loginSuccess(user);
    }
  }

  useEffect(() => {
    var matchingMenuItem = null
    var ul = document.getElementById("navigation")
    var items = ul.getElementsByTagName("a")
    for (var i = 0; i < items.length; ++i) {
      if (props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  })
  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active") // li
      const parent2 = parent.parentElement
      parent2.classList.add("active") // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add("active") // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add("active") // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add("active") // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add("active") // li
            }
          }
        }
      }
    }
    return false
  }

  return (
    <React.Fragment>
      <div className="topnav">
        <nav
          className="navbar navbar-light navbar-expand-lg topnav-menu"
          id="navigation"
        >
          <Collapse
            isOpen={props.leftMenu}
            className="navbar-collapse"
            id="topnav-menu-content"
          >
            {user?.role == 'Admin' ?
              <ul className="navbar-nav">
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    to="#"
                  >
                    {props.t("Users")}{" "}
                    <div className="arrow-down"></div>
                  </Link>
                  <div
                    className={classname("dropdown-menu")}
                  >
                    <Link className="dropdown-item" to="/all_users">{props.t("All Users")}</Link>
                    <Link className="dropdown-item" to="/user/unregistered">{props.t("Unregistered Users")}</Link>
                    <Link className="dropdown-item" to="/user/unregistered-to-registered">{props.t("Unregistered to Registered")}</Link>
                    <Link className="dropdown-item" to="/user/registered">{props.t("Registered Users")}</Link>
                    <Link className="dropdown-item" to="/user/registered-to-paid">{props.t("Registered to Paid")}</Link>
                    <Link className="dropdown-item" to="/user/paid">{props.t("Paid Users")}</Link>

                  </div>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    to="#"
                  >
                    {props.t("Renewal")}{" "}
                    <div className="arrow-down"></div>
                  </Link>
                  <div
                    className={classname("dropdown-menu")}
                  >
                    <Link className="dropdown-item" to="/user/all-paid">{props.t("All")}</Link>
                    <Link className="dropdown-item" to="/user/renewed">{props.t("Renewed")}</Link>
                    <Link className="dropdown-item" to="/user/renew-in-15">{props.t("15 Days to Renew")}</Link>
                    <Link className="dropdown-item" to="/user/renew-in-5">{props.t("5 Days to Renew")}</Link>
                    <Link className="dropdown-item" to="/user/renew-due-5">{props.t("5 Days not Renewed")}</Link>
                    <Link className="dropdown-item" to="/user/renew-due-15">{props.t("15 Days not Renewed")}</Link>

                  </div>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    to="#"
                  >
                    {props.t("Content")}{" "}
                    <div className="arrow-down"></div>
                  </Link>
                  <div
                    className={classname("dropdown-menu")}
                  >
                    <Link className="dropdown-item" to="/tags">{props.t("Tags")}</Link>
                    <Link className="dropdown-item" to="/category">{props.t("Category")}</Link>
                    <Link className="dropdown-item" to="/content">{props.t("Content")}</Link>

                  </div>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    to="#"
                  >
                    {props.t("Talk To Me")}{" "}
                    <div className="arrow-down"></div>
                  </Link>
                  <div
                    className={classname("dropdown-menu")}
                  >
                    <Link className="dropdown-item" to="/talk-intro">{props.t("Introduction")}</Link>
                    {/* <Link className="dropdown-item" to="/talk-category">{props.t("Category")}</Link> */}
                    <Link className="dropdown-item" to="/talk-ques">{props.t("Question")}</Link>
                    <Link className="dropdown-item" to="/talks">{props.t("Sessions")}</Link>

                  </div>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    to="#"
                  >
                    {props.t("Harmony")}{" "}
                    <div className="arrow-down"></div>
                  </Link>
                  <div
                    className={classname("dropdown-menu")}
                  >
                    <Link className="dropdown-item" to="/harmony_mood">{props.t("Mood")}</Link>
                    <Link className="dropdown-item" to="/harmony_category">{props.t("Sound Category")}</Link>
                    <Link className="dropdown-item" to="/harmony_sound">{props.t("Sounds")}</Link>

                  </div>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    to="#"
                  >
                    {props.t("Know Yourself")}{" "}
                    <div className="arrow-down"></div>
                  </Link>
                  <div
                    className={classname("dropdown-menu")}
                  >
                    <Link className="dropdown-item" to="/know-yourself-category">{props.t("Category")}</Link>
                    <Link className="dropdown-item" to="/know-yourself-intro">{props.t("Introduction")}</Link>
                    <Link className="dropdown-item" to="/know-yourself-question">{props.t("Questions")}</Link>
                    <Link className="dropdown-item" to="/know-yourself-answer">{props.t("Answers")}</Link>

                  </div>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    to="#"
                  >
                    {props.t("Quotes")}{" "}
                    <div className="arrow-down"></div>
                  </Link>
                  <div
                    className={classname("dropdown-menu")}
                  >
                    <Link className="dropdown-item" to="/quotes/universal">{props.t("Universal")}</Link>
                    <Link className="dropdown-item" to="/quotes/country">{props.t("Country")}</Link>
                    <Link className="dropdown-item" to="/quotes/general">{props.t("General")}</Link>

                  </div>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    to="#"
                  >
                    {props.t("Blog")}{" "}
                    <div className="arrow-down"></div>
                  </Link>
                  <div
                    className={classname("dropdown-menu")}
                  >
                    <Link className="dropdown-item" to="/blog">{props.t("Blog")}</Link>
                    <Link className="dropdown-item" to="/blog-category">{props.t("Category")}</Link>

                  </div>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    to="#"
                  >
                    {props.t("Podcast")}{" "}
                    <div className="arrow-down"></div>
                  </Link>
                  <div
                    className={classname("dropdown-menu")}
                  >
                    <Link className="dropdown-item" to="/podcast">{props.t("Podcasts")}</Link>
                    <Link className="dropdown-item" to="/podcast_request">{props.t("Podcast Request")}</Link>

                  </div>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    to="/notification"
                    className="nav-link dropdown-toggle arrow-none"
                  >
                    {props.t("Notifications")}
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    to="/coupon"
                    className="nav-link dropdown-toggle arrow-none"
                  >
                    {props.t("Coupon")}
                  </Link>
                </li>
              </ul>
              :
              <ul className="navbar-nav">
                {user?.permissions?.length && user?.permissions[0]?.user?.read ?
                  <>
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        to="#"
                      >
                        {props.t("Users")}{" "}
                        <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu")}
                      >
                        <Link className="dropdown-item" to="/users">{props.t("All Users")}</Link>
                        <Link className="dropdown-item" to="/user/unregistered">{props.t("Unregistered Users")}</Link>
                        <Link className="dropdown-item" to="/user/unregistered-to-registered">{props.t("Unregistered to Registered")}</Link>
                        <Link className="dropdown-item" to="/user/registered">{props.t("Registered Users")}</Link>
                        <Link className="dropdown-item" to="/user/registered-to-paid">{props.t("Registered to Paid")}</Link>
                        <Link className="dropdown-item" to="/user/paid">{props.t("Paid Users")}</Link>

                      </div>
                    </li>
                    {/* <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        to="#"
                      >
                        {props.t("Renewal")}{" "}
                        <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu")}
                      >
                        <Link className="dropdown-item" to="/user/all-paid">{props.t("All")}</Link>
                        <Link className="dropdown-item" to="/user/renewed">{props.t("Renewed")}</Link>
                        <Link className="dropdown-item" to="/user/renew-in-15">{props.t("15 Days to Renew")}</Link>
                        <Link className="dropdown-item" to="/user/renew-in-5">{props.t("5 Days to Renew")}</Link>
                        <Link className="dropdown-item" to="/user/renew-due-5">{props.t("5 Days not Renewed")}</Link>
                        <Link className="dropdown-item" to="/user/renew-due-15">{props.t("15 Days not Renewed")}</Link>

                      </div>
                    </li> */}
                  </>
                  : null}
                {user?.permissions?.length && user?.permissions[10]?.content?.read ?
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      to="#"
                    >
                      {props.t("Content")}{" "}
                      <div className="arrow-down"></div>
                    </Link>
                    <div
                      className={classname("dropdown-menu")}
                    >
                      <Link className="dropdown-item" to="/tags">{props.t("Tags")}</Link>
                      <Link className="dropdown-item" to="/category">{props.t("Category")}</Link>
                      <Link className="dropdown-item" to="/content">{props.t("Content")}</Link>

                    </div>
                  </li>
                  : null}
                {user?.permissions?.length && user?.permissions[13]?.talk_to_me?.read ?
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      to="#"
                    >
                      {props.t("Talk To Me")}{" "}
                      <div className="arrow-down"></div>
                    </Link>
                    <div
                      className={classname("dropdown-menu")}
                    >
                      <Link className="dropdown-item" to="/talk-intro">{props.t("Introduction")}</Link>
                      {/* <Link className="dropdown-item" to="/talk-category">{props.t("Category")}</Link> */}
                      <Link className="dropdown-item" to="/talk-ques">{props.t("Question")}</Link>
                      <Link className="dropdown-item" to="/talks">{props.t("Sessions")}</Link>

                    </div>
                  </li>
                  : null}
                {user?.permissions?.length && user?.permissions[6]?.harmony?.read ?
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      to="#"
                    >
                      {props.t("Harmony")}{" "}
                      <div className="arrow-down"></div>
                    </Link>
                    <div
                      className={classname("dropdown-menu")}
                    >
                      <Link className="dropdown-item" to="/harmony_mood">{props.t("Mood")}</Link>
                      <Link className="dropdown-item" to="/harmony_category">{props.t("Sound Category")}</Link>
                      <Link className="dropdown-item" to="/harmony_sound">{props.t("Sounds")}</Link>

                    </div>
                  </li>
                  : null}
                {user?.permissions?.length && user?.permissions[7]?.knowyourself?.read ?
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      to="#"
                    >
                      {props.t("Know Yourself")}{" "}
                      <div className="arrow-down"></div>
                    </Link>
                    <div
                      className={classname("dropdown-menu")}
                    >
                      <Link className="dropdown-item" to="/know-yourself-category">{props.t("Category")}</Link>
                      <Link className="dropdown-item" to="/know-yourself-intro">{props.t("Introduction")}</Link>
                      <Link className="dropdown-item" to="/know-yourself-question">{props.t("Questions")}</Link>

                    </div>
                  </li>
                  : null}
                {user?.permissions?.length && user?.permissions[2]?.quote?.read ?
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      to="#"
                    >
                      {props.t("Quotes")}{" "}
                      <div className="arrow-down"></div>
                    </Link>
                    <div
                      className={classname("dropdown-menu")}
                    >
                      <Link className="dropdown-item" to="/quotes/universal">{props.t("Universal")}</Link>
                      <Link className="dropdown-item" to="/quotes/country">{props.t("Country")}</Link>
                      <Link className="dropdown-item" to="/quotes/general">{props.t("General")}</Link>

                    </div>
                  </li>
                  : null}
                {user?.permissions?.length && user?.permissions[22]?.blog?.read ?
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      to="#"
                    >
                      {props.t("Blog")}{" "}
                      <div className="arrow-down"></div>
                    </Link>
                    <div
                      className={classname("dropdown-menu")}
                    >
                      <Link className="dropdown-item" to="/blog">{props.t("Blog")}</Link>
                      <Link className="dropdown-item" to="/blog-category">{props.t("Category")}</Link>

                    </div>
                  </li>
                  : null}
                {user?.permissions?.length && user?.permissions[15]?.podcast?.read ?
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      to="#"
                    >
                      {props.t("Podcast")}{" "}
                      <div className="arrow-down"></div>
                    </Link>
                    <div
                      className={classname("dropdown-menu")}
                    >
                      <Link className="dropdown-item" to="/podcast">{props.t("Podcasts")}</Link>
                      <Link className="dropdown-item" to="/podcast_request">{props.t("Podcast Request")}</Link>

                    </div>
                  </li>
                  : null}
                {user?.permissions?.length && user?.permissions[20]?.notification?.read ?
                  <li className="nav-item dropdown">
                    <Link
                      to="/notification"
                      className="nav-link dropdown-toggle arrow-none"
                    >
                      {props.t("Notifications")}
                    </Link>
                  </li>
                  : null}
                {user?.permissions?.length && user?.permissions[17]?.coupon?.read ?
                  <li className="nav-item dropdown">
                    <Link
                      to="/coupon"
                      className="nav-link dropdown-toggle arrow-none"
                    >
                      {props.t("Coupon")}
                    </Link>
                  </li>
                  : null}
              </ul>
            }
          </Collapse>
        </nav>
      </div>
    </React.Fragment>
  )
}

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
  getProfile: PropTypes.func
}

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout;
  return { leftMenu, user: state.Profile?.user }
}

export default withRouter(
  connect(mapStatetoProps, { getProfile })(withTranslation()(Navbar))
)