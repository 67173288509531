import PropTypes from "prop-types";
import React, { useEffect, useState } from "react"
import { Row, Col, CardBody, Card, Progress, TabContent, TabPane, Nav, NavItem, NavLink, Badge, Label, CardTitle } from "reactstrap"
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { getProfile } from '../../store/auth/profile/actions';
import MiniWidget from '../Dashboard2/MiniWidget';
import SubsWidget from '../Dashboard2/SubsWidget';
import EarningChart from '../Dashboard2/EarningChart';
import widgetImage from "../../assets/images/widget-img.png"
import { get } from "../../helpers/api_helper";
import Utils from "../Utility";
import OverviewDashboard from "./OverviewDashboard";
import Reviews from "./Reviews";
import Revenue from "./Revenue";
import classnames from "classnames"
import Setting from "./SettingDash";
import SubsChart from "./SubsChart";
import PlanChart from "./PlanChart";
import ExpireChart from "./expireChart";
import UsedChart from "./UsedChart";
import ThemeChart from "./ThemeChart";
import PodcastChart from "./PodcastChart";
import WellnessChart from "./WellnessChart";
import YogaChart from "./knob";
import WatchWidget from "./watchedWidget";
import PodcastRequest from "./PodcastRequest";
import TTMRequest from "./TTMRequest";
import RecentNoti from "./RecentNoti";
import ContentChart from "./ContentChart";
import CategoryChart from "../AllCharts/apex/RadialChart";
import Loader from "../../components/Loader";
import { showRightSidebarAction, toggleLeftmenu } from "../../store/actions"

const Dashboard = (props) => {
  const { user } = props;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(undefined);
  const [activeTabJustify2, setactiveTabJustify2] = useState("1")

  function toggleCustomJustified2(tab) {
    if (activeTabJustify2 !== tab) {
      setactiveTabJustify2(tab)
    }
  }

  useEffect(() => {
    console.log('props on home ', user);
    if (user?.token && user?.role == "Admin") {
      getStats();
    }
  }, [user, activeTabJustify2]);

  useEffect(() => {
    if (user?.role == "Admin")
      setData(undefined);
  }, [user])

  const getStats = () => {
    if (!loading) {
      setLoading(true);
      get(`/screen/stat?tab=${activeTabJustify2}`, { token: user?.token })
        .then(json => {
          // console.log('response from get stat', json);
          setLoading(false);
          if (json?.statusCode == 200) {
            if (user?.role == "Admin")
              setData(json?.data);
          }
        })
        .catch(error => {
          if (user?.role != "Admin")
            setData(undefined);
          setLoading(false);
          console.error('Error while getting stat', error);
        });
    }
  }

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  // console.log("data on dashboard", data?.yoga);
  const darkColors = Array.from({ length: 30 }, () => {
    const randomColor = () => Math.floor(Math.random() * 256).toString(16).padStart(2, '0');
    return `#${randomColor()}${randomColor()}${randomColor()}`;
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Loader visible={loading} />
        <Row>
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">Welcome to {Utils.projectName} Dashboard</li>
                </ol>
              </div>
              <div className="right-section-breadcrumb">
                <button
                  type="button"
                  onClick={() => {
                    toggleFullscreen();
                  }}
                  className="btn noti-icon waves-effect"
                  data-toggle="fullscreen"
                >
                  <i className="mdi mdi-fullscreen" style={{ color: 'white' }}></i>
                </button>
                <Link to="/logout" className="dropdown-item text-white">
                  <i className="bx bx-power-off font-size-16 align-middle me-1 text-white"></i>{" "}
                  <span>{'Logout'}</span>
                </Link>
                <div className="dropdown d-inline-block">
                  <button
                    onClick={() => {
                      props.showRightSidebarAction(!props.showRightSidebar)
                    }}
                    type="button"
                    className="btn header-item noti-icon right-bar-toggle waves-effect"
                  >
                    <i className="mdi mdi-settings-outline"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Row>
        {data || activeTabJustify2 == "4" ?
          <Row className="mt-5 pt-3 hori_mr_0">
            <Col lg={12}>
              <Nav pills className="nav-justified">
                <NavItem className="waves-effect waves-light">
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTabJustify2 === "1",
                    })}
                    onClick={() => {
                      toggleCustomJustified2("1")
                    }}
                  >
                    <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                    <span className="d-none d-sm-block">Users</span>
                  </NavLink>
                </NavItem>
                <NavItem className="waves-effect waves-light">
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTabJustify2 === "3",
                    })}
                    onClick={() => {
                      toggleCustomJustified2("3")
                    }}
                  >
                    <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                    <span className="d-none d-sm-block">Subscription</span>
                  </NavLink>
                </NavItem>
                <NavItem className="waves-effect waves-light">
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTabJustify2 === "2",
                    })}
                    onClick={() => {
                      toggleCustomJustified2("2")
                    }}
                  >
                    <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                    <span className="d-none d-sm-block">Content</span>
                  </NavLink>
                </NavItem>
                <NavItem className="waves-effect waves-light">
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTabJustify2 === "5",
                    })}
                    onClick={() => {
                      toggleCustomJustified2("5")
                    }}
                  >
                    <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                    <span className="d-none d-sm-block">Content Watched</span>
                  </NavLink>
                </NavItem>
                <NavItem className="waves-effect waves-light">
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTabJustify2 === "4",
                    })}
                    onClick={() => {
                      toggleCustomJustified2("4")
                    }}
                  >
                    <span className="d-block d-sm-none"><i className="fas fa-cog"></i></span>
                    <span className="d-none d-sm-block">Settings</span>
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={activeTabJustify2} className="p-3 text-muted">
                <TabPane tabId="1">
                  <Row className="pt-3">
                    <Col lg={12}>
                      <h4 className="card-title-d mb-4">Users Management</h4>
                    </Col>
                    <Col xl={12}>
                      <MiniWidget users={data?.users} />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <OverviewDashboard users={data?.users} />
                    <Reviews devices={data?.devices} allUser={data?.users?.allUser} />
                    <Col lg={3} className="dashboard-second-row">
                      <Card >
                        <CardBody>
                          <div className="d-flex align-items-start">
                            <div className="avatar-sm font-size-14 me-3">
                              <span className="avatar-title bg-soft-primary text-primary rounded">
                                <i className="mdi mdi-account-multiple-outline"></i>
                              </span>
                            </div>
                            <div className="flex-1">
                              <div className="font-size-12 mt-2">Weekly Returning Users</div>
                            </div>
                          </div>
                          <h4 className="mt-4">{Number(data?.weekRegisterd).toLocaleString()}</h4>
                          <div className="row">

                            <div className="col-5 align-self-center">

                              <Progress
                                value={String(((Number(data?.weekRegisterd) * 100) / Number(data?.users?.allUser)).toFixed(0))}
                                color="primary"
                                className="bg-transparent progress-sm"
                                size="sm"
                              />
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                      <Card>
                        <CardBody>
                          <div className="d-flex align-items-start">
                            <div className="avatar-sm font-size-14 me-3">
                              <span className="avatar-title bg-soft-primary text-primary rounded">
                                <i className="mdi mdi-account-multiple-outline"></i>
                              </span>
                            </div>
                            <div className="flex-1">
                              <div className="font-size-12 mt-2">Weekly New Users
                              </div>

                            </div>
                          </div>
                          <h4 className="mt-4">{Number(data?.weekNew).toLocaleString()}</h4>
                          <Row>

                            <div className="col-5 align-self-center">
                              <Progress
                                value={String(((Number(data?.weekNew) * 100) / Number(data?.users?.allUser)).toFixed(0))}
                                color="success"
                                className="bg-transparent progress-sm"
                                size="sm"
                              />
                            </div>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg={3}>
                      <Card className="bg-primary" style={{ height: '93%' }}>
                        <CardBody>
                          {/* <div className="text-white-50">
                          <h5 className="text-white">2400 + New Users</h5>
                          <p>At vero eos et accusamus et iusto odio dignissimos ducimus</p>
                          <div>
                            <Link to="#" className="btn btn-outline-success btn-sm">View more</Link>
                          </div>
                        </div> */}
                          <Row className="justify-content-end">
                            <div className="col-12">
                              <div className="mt-4">
                                <img src={widgetImage} alt=""
                                  className="img-fluid mx-auto d-block" />
                              </div>
                            </div>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg={6}>
                      <EarningChart data={data?.paidMonth} total={data?.users?.paid} />
                    </Col>
                    <Revenue data={data?.country} />
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row className="mt-3">
                    <Col lg={12}>
                      <h4 className="card-title-d mb-4">Content Management</h4>
                    </Col>
                    <React.Fragment>
                      <Row className='dashboard-card-box-section'>
                        <Col lg={3} className="mb-4">
                          <Card className='card-section-dashboard card-section-dashboard-box ' style={{ height: '100%' }}>
                            <CardBody>
                              <Row className="align-items-center">
                                <div className="d-flex align-items-start">
                                  <div className="avatar-sm font-size-14">
                                    <span className=" text-primary rounded">
                                      <i className="mdi mdi-compare"></i>
                                    </span>
                                  </div>
                                  <div className="flex-1">
                                    <div className="font-size-12">Themes</div>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <h4 className="mb-0">{Number(data?.theme?.total).toLocaleString()}</h4>
                                </div>

                                <div>
                                  <div className="mt-1 mb-3">
                                    <Progress
                                      value={String(((Number(data?.theme?.active) * 100) / Number(data?.theme?.total)).toFixed(0))}
                                      color="success"
                                      className="bg-transparent progress-sm"
                                      size="sm"
                                    />
                                  </div>
                                  <div className="mb-3 mt-3 ">

                                    <Badge className="bg-success py-2">
                                      Active ({Number(data?.theme?.active).toLocaleString()})
                                    </Badge>{" "}

                                    <Badge className="bg-warning py-2">
                                      Inactive ({Number(data?.theme?.inActive).toLocaleString()})
                                    </Badge>{" "}
                                  </div>
                                  <div className="mt-1">
                                    <Link to="/theme" className="btn btn_bg btn-sm">View <i className="mdi mdi-arrow-right ms-1" /></Link>
                                  </div>
                                </div>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col lg={3} className="mb-4">
                          <Card className='card-section-dashboard card-section-dashboard-box ' style={{ height: '100%' }}>
                            <CardBody>
                              <Row className="align-items-center">
                                <div className="d-flex align-items-start">
                                  <div className="avatar-sm font-size-14">
                                    <span className=" text-primary rounded">
                                      <i className="mdi mdi-meditation"></i>
                                    </span>
                                  </div>
                                  <div className="flex-1">
                                    <div className="font-size-12">Wellness</div>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <h4 className="mb-0">{Number(data?.wellness?.total).toLocaleString()}</h4>
                                </div>

                                <div>
                                  <div className="mt-1 mb-3">
                                    <Progress
                                      value={String(((Number(data?.wellness?.active) * 100) / Number(data?.wellness?.total)).toFixed(0))}
                                      color="success"
                                      className="bg-transparent progress-sm"
                                      size="sm"
                                    />
                                  </div>
                                  <div className="mb-3 mt-3 ">

                                    <Badge className="bg-success py-2">
                                      Active ({Number(data?.wellness?.active).toLocaleString()})
                                    </Badge>{" "}

                                    <Badge className="bg-warning py-2">
                                      Inactive ({Number(data?.wellness?.inActive).toLocaleString()})
                                    </Badge>{" "}
                                  </div>
                                  <div className="mt-1">
                                    <Link to="/wellness" className="btn btn_bg btn-sm">View <i className="mdi mdi-arrow-right ms-1" /></Link>
                                  </div>
                                </div>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col lg={3} className="mb-4">
                          <Card className='card-section-dashboard card-section-dashboard-box ' style={{ height: '100%' }}>
                            <CardBody>
                              <Row className="align-items-center">
                                <div className="d-flex align-items-start">
                                  <div className="avatar-sm font-size-14">
                                    <span className=" text-primary rounded">
                                      <i className="mdi mdi-yoga"></i>
                                    </span>
                                  </div>
                                  <div className="flex-1">
                                    <div className="font-size-12">Yoga</div>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <h4 className="mb-0">{Number(data?.yoga?.total).toLocaleString()}</h4>
                                </div>

                                <div>
                                  <div className="mt-1 mb-3">
                                    <Progress
                                      value={String(((Number(data?.yoga?.active) * 100) / Number(data?.yoga?.total)).toFixed(0))}
                                      color="success"
                                      className="bg-transparent progress-sm"
                                      size="sm"
                                    />
                                  </div>
                                  <div className="mb-3 mt-3 ">

                                    <Badge className="bg-success py-2">
                                      Active ({Number(data?.yoga?.active).toLocaleString()})
                                    </Badge>{" "}

                                    <Badge className="bg-warning py-2">
                                      Inactive ({Number(data?.yoga?.inActive).toLocaleString()})
                                    </Badge>{" "}
                                  </div>
                                  <div className="mt-1">
                                    <Link to="/yoga" className="btn btn_bg btn-sm">View <i className="mdi mdi-arrow-right ms-1" /></Link>
                                  </div>
                                </div>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col lg={3} className="mb-4">
                          <Card className='card-section-dashboard card-section-dashboard-box ' style={{ height: '100%' }}>
                            <CardBody>
                              <Row className="align-items-center">
                                <div className="d-flex align-items-start">
                                  <div className="avatar-sm font-size-14">
                                    <span className=" text-primary rounded">
                                      <i className="mdi mdi-shap"></i>
                                    </span>
                                  </div>
                                  <div className="flex-1">
                                    <div className="font-size-12">Category</div>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <h4 className="mb-0">{Number(data?.category?.total).toLocaleString()}</h4>
                                </div>

                                <div>
                                  <div className="mt-1 mb-3">
                                    <Progress
                                      value={String(((Number(data?.category?.active) * 100) / Number(data?.category?.total)).toFixed(0))}
                                      color="success"
                                      className="bg-transparent progress-sm"
                                      size="sm"
                                    />
                                  </div>
                                  <div className="mb-3 mt-3 ">

                                    <Badge className="bg-success py-2">
                                      Active ({Number(data?.category?.active).toLocaleString()})
                                    </Badge>{" "}

                                    <Badge className="bg-warning py-2">
                                      Inactive ({Number(data?.category?.inActive).toLocaleString()})
                                    </Badge>{" "}
                                  </div>
                                  <div className="mt-1">
                                    <Link to="/category" className="btn btn_bg btn-sm">View <i className="mdi mdi-arrow-right ms-1" /></Link>
                                  </div>
                                </div>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col lg={3} className="mb-4">
                          <Card className='card-section-dashboard card-section-dashboard-box ' style={{ height: '100%' }}>
                            <CardBody>
                              <Row className="align-items-center">
                                <div className="d-flex align-items-start">
                                  <div className="avatar-sm font-size-14">
                                    <span className=" text-primary rounded">
                                      <i className="mdi mdi-tag-multiple"></i>
                                    </span>
                                  </div>
                                  <div className="flex-1">
                                    <div className="font-size-12">Tags</div>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <h4 className="mb-0">{Number(data?.tags?.total).toLocaleString()}</h4>
                                </div>

                                <div>
                                  <div className="mt-1 mb-3">
                                    <Progress
                                      value={String(((Number(data?.tags?.active) * 100) / Number(data?.tags?.total)).toFixed(0))}
                                      color="success"
                                      className="bg-transparent progress-sm"
                                      size="sm"
                                    />
                                  </div>
                                  <div className="mb-3 mt-3 ">

                                    <Badge className="bg-success py-2">
                                      Active ({Number(data?.tags?.active).toLocaleString()})
                                    </Badge>{" "}

                                    <Badge className="bg-warning py-2">
                                      Inactive ({Number(data?.tags?.inActive).toLocaleString()})
                                    </Badge>{" "}
                                  </div>
                                  <div className="mt-1">
                                    <Link to="/tags" className="btn btn_bg btn-sm">View <i className="mdi mdi-arrow-right ms-1" /></Link>
                                  </div>
                                </div>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col lg={3} className="mb-4">
                          <Card className='card-section-dashboard card-section-dashboard-box ' style={{ height: '100%' }}>
                            <CardBody>
                              <Row className="align-items-center">
                                <div className="d-flex align-items-start">
                                  <div className="avatar-sm font-size-14">
                                    <span className=" text-primary rounded">
                                      <i className="mdi mdi-gamepad"></i>
                                    </span>
                                  </div>
                                  <div className="flex-1">
                                    <div className="font-size-12">Games</div>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <h4 className="mb-0">{Number(data?.games?.total).toLocaleString()}</h4>
                                </div>

                                <div>
                                  <div className="mt-1 mb-3">
                                    <Progress
                                      value={String(((Number(data?.games?.inActive) * 100) / Number(data?.games?.total)).toFixed(0))}
                                      color="danger"
                                      className="bg-transparent progress-sm"
                                      size="sm"
                                    />
                                  </div>
                                  <div className="mb-3 mt-3 ">

                                    <Badge className="bg-success py-2">
                                      Active ({Number(data?.games?.active).toLocaleString()})
                                    </Badge>{" "}

                                    <Badge className="bg-warning py-2">
                                      Inactive ({Number(data?.games?.inActive).toLocaleString()})
                                    </Badge>{" "}
                                  </div>
                                  <div className="mt-1">
                                    <Link to="/games" className="btn btn_bg btn-sm">View <i className="mdi mdi-arrow-right ms-1" /></Link>
                                  </div>
                                </div>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col lg={3} className="mb-4">
                          <Card className='card-section-dashboard card-section-dashboard-box ' style={{ height: '100%' }}>
                            <CardBody>
                              <Row className="align-items-center">
                                <div className="d-flex align-items-start">
                                  <div className="avatar-sm font-size-14">
                                    <span className=" text-primary rounded">
                                      <i className="mdi mdi-home-group"></i>
                                    </span>
                                  </div>
                                  <div className="flex-1">
                                    <div className="font-size-12">Communities</div>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <h4 className="mb-0">{Number(data?.community?.total).toLocaleString()}</h4>
                                </div>

                                <div>
                                  <div className="mt-1 mb-3">
                                    <Progress
                                      value={String(((Number(data?.community?.active) * 100) / Number(data?.community?.total)).toFixed(0))}
                                      color="success"
                                      className="bg-transparent progress-sm"
                                      size="sm"
                                    />
                                  </div>
                                  <div className="mb-3 mt-3 ">

                                    <Badge className="bg-success py-2">
                                      Active ({Number(data?.community?.active).toLocaleString()})
                                    </Badge>{" "}

                                    <Badge className="bg-warning py-2">
                                      Inactive ({Number(data?.community?.inActive).toLocaleString()})
                                    </Badge>{" "}
                                  </div>
                                  <div className="mt-1">
                                    <Link to="/communities" className="btn btn_bg btn-sm">View <i className="mdi mdi-arrow-right ms-1" /></Link>
                                  </div>
                                </div>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col lg={3} className="mb-4">
                          <Card className='card-section-dashboard card-section-dashboard-box ' style={{ height: '100%' }}>
                            <CardBody>
                              <Row className="align-items-center">
                                <div className="d-flex align-items-start">
                                  <div className="avatar-sm font-size-14">
                                    <span className=" text-primary rounded">
                                      <i className="mdi mdi-youtube-tv"></i>
                                    </span>
                                  </div>
                                  <div className="flex-1">
                                    <div className="font-size-12">Live Session</div>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <h4 className="mb-0">{Number(data?.liveSession?.total).toLocaleString()}</h4>
                                </div>

                                <div>
                                  <div className="mt-1 mb-3">
                                    <Progress
                                      value={String(((Number(data?.liveSession?.inActive) * 100) / Number(data?.liveSession?.total)).toFixed(0))}
                                      color="danger"
                                      className="bg-transparent progress-sm"
                                      size="sm"
                                    />
                                  </div>
                                  <div className="mb-3 mt-3 ">

                                    <Badge className="bg-success py-2">
                                      Active ({Number(data?.liveSession?.active).toLocaleString()})
                                    </Badge>{" "}

                                    <Badge className="bg-warning py-2">
                                      Inactive ({Number(data?.liveSession?.inActive).toLocaleString()})
                                    </Badge>{" "}
                                  </div>
                                  <div className="mt-1">
                                    <Link to="/live_session" className="btn btn_bg btn-sm">View <i className="mdi mdi-arrow-right ms-1" /></Link>
                                  </div>
                                </div>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col lg={3} className="mb-4">
                          <Card className='card-section-dashboard card-section-dashboard-box ' style={{ height: '100%' }}>
                            <CardBody>
                              <Row className="align-items-center">
                                <div className="d-flex align-items-start">
                                  <div className="avatar-sm font-size-14">
                                    <span className=" text-primary rounded">
                                      <i className="mdi mdi-cast-audio"></i>
                                    </span>
                                  </div>
                                  <div className="flex-1">
                                    <div className="font-size-12">Podcast</div>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <h4 className="mb-0">{Number(data?.podcast?.total).toLocaleString()}</h4>
                                </div>

                                <div>
                                  <div className="mt-1 mb-3">
                                    <Progress
                                      value={String(((Number(data?.podcast?.active) * 100) / Number(data?.podcast?.total)).toFixed(0))}
                                      color="success"
                                      className="bg-transparent progress-sm"
                                      size="sm"
                                    />
                                  </div>
                                  <div className="mb-3 mt-3 ">

                                    <Badge className="bg-success py-2">
                                      Active ({Number(data?.podcast?.active).toLocaleString()})
                                    </Badge>{" "}

                                    <Badge className="bg-warning py-2">
                                      Inactive ({Number(data?.podcast?.inActive).toLocaleString()})
                                    </Badge>{" "}
                                  </div>
                                  <div className="mt-1">
                                    <Link to="/podcast" className="btn btn_bg btn-sm">View <i className="mdi mdi-arrow-right ms-1" /></Link>
                                  </div>
                                </div>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col lg={3} className="mb-4">
                          <Card className='card-section-dashboard card-section-dashboard-box ' style={{ height: '100%' }}>
                            <CardBody>
                              <Row className="align-items-center">
                                <div className="d-flex align-items-start">
                                  <div className="avatar-sm font-size-14">
                                    <span className=" text-primary rounded">
                                      <i className="mdi mdi-ticket-percent"></i>
                                    </span>
                                  </div>
                                  <div className="flex-1">
                                    <div className="font-size-12">Coupon</div>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <h4 className="mb-0">{Number(data?.coupon?.total).toLocaleString()}</h4>
                                </div>

                                <div>
                                  <div className="mt-1 mb-3">
                                    <Progress
                                      value={String(((Number(data?.coupon?.inActive) * 100) / Number(data?.coupon?.total)).toFixed(0))}
                                      color="danger"
                                      className="bg-transparent progress-sm"
                                      size="sm"
                                    />
                                  </div>
                                  <div className="mb-3 mt-3 ">

                                    <Badge className="bg-success py-2">
                                      Used ({Number(data?.coupon?.active).toLocaleString()})
                                    </Badge>{" "}

                                    <Badge className="bg-warning py-2">
                                      Unused ({Number(data?.coupon?.inActive).toLocaleString()})
                                    </Badge>{" "}
                                  </div>
                                  <div className="mt-1">
                                    <Link to="/coupon" className="btn btn_bg btn-sm">View <i className="mdi mdi-arrow-right ms-1" /></Link>
                                  </div>
                                </div>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col lg={3} className="mb-4">
                          <Card className='card-section-dashboard card-section-dashboard-box ' style={{ height: '100%' }}>
                            <CardBody>
                              <Row className="align-items-center">
                                <div className="d-flex align-items-start">
                                  <div className="avatar-sm font-size-14">
                                    <span className=" text-primary rounded">
                                      <i className="mdi mdi-key"></i>
                                    </span>
                                  </div>
                                  <div className="flex-1">
                                    <div className="font-size-12">Sub-Admin</div>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <h4 className="mb-0">{Number(data?.subAdmin?.total).toLocaleString()}</h4>
                                </div>

                                <div>
                                  <div className="mt-1 mb-3">
                                    <Progress
                                      value={String(((Number(data?.subAdmin?.active) * 100) / Number(data?.subAdmin?.total)).toFixed(0))}
                                      color="success"
                                      className="bg-transparent progress-sm"
                                      size="sm"
                                    />
                                  </div>
                                  <div className="mb-3 mt-3 ">
                                    <Badge className="bg-success py-2">
                                      Active ({Number(data?.subAdmin?.active).toLocaleString()})
                                    </Badge>{" "}
                                    <Badge className="bg-warning py-2">
                                      Inactive ({Number(data?.subAdmin?.inActive).toLocaleString()})
                                    </Badge>{" "}
                                  </div>
                                  <div className="mt-1">
                                    <Link to="/sub-admin" className="btn btn_bg btn-sm">View <i className="mdi mdi-arrow-right ms-1" /></Link>
                                  </div>
                                </div>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col lg={3} className="mb-4">
                          <Card className='card-section-dashboard card-section-dashboard-box ' style={{ height: '100%' }}>
                            <CardBody>
                              <Row className="align-items-center">
                                <div className="d-flex align-items-start">
                                  <div className="avatar-sm font-size-14">
                                    <span className=" text-primary rounded">
                                      <i className="mdi mdi-cellphone-screenshot"></i>
                                    </span>
                                  </div>
                                  <div className="flex-1">
                                    <div className="font-size-12">Popup</div>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <h4 className="mb-0">{Number(data?.popup?.total).toLocaleString()}</h4>
                                </div>

                                <div>
                                  <div className="mt-1 mb-3">
                                    <Progress
                                      value={String(((Number(data?.popup?.inActive) * 100) / Number(data?.popup?.total)).toFixed(0))}
                                      color="danger"
                                      className="bg-transparent progress-sm"
                                      size="sm"
                                    />
                                  </div>
                                  <div className="mb-3 mt-3 ">

                                    <Badge className="bg-success py-2">
                                      Active ({Number(data?.popup?.active).toLocaleString()})
                                    </Badge>{" "}

                                    <Badge className="bg-warning py-2">
                                      Inactive ({Number(data?.popup?.inActive).toLocaleString()})
                                    </Badge>{" "}
                                  </div>
                                  <div className="mt-1">
                                    <Link to="/popup" className="btn btn_bg btn-sm">View <i className="mdi mdi-arrow-right ms-1" /></Link>
                                  </div>
                                </div>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </React.Fragment>
                    <Row className="card-section-dashboard">
                      <Col lg={6}>
                        <Card>
                          <CardBody>
                            <Row className="mt-4">
                              <Col lg={12}>
                                <h4 className="card-title mb-4"><i className="mdi mdi-format-quote-close"></i> Quotes</h4>
                              </Col>
                              <Col xl={4}>
                                <div>
                                  <div >
                                    <Row className="align-items-center">
                                      <Col xs={12}>
                                        <p className="mb-2">Universal</p>
                                        <h4 className="mb-0">{Number(data?.quotes?.find(x => x?.type == "Universal")?.total)?.toLocaleString()}</h4>
                                      </Col>

                                    </Row>
                                    <div className="mb-3 badge-list  mt-3 ">

                                      <Badge className="bg-success py-2">
                                        Active ({Number(data?.quotes?.find(x => x?.type == "Universal")?.active).toLocaleString()})
                                      </Badge>{" "}

                                      <Badge className="bg-warning py-2">
                                        Inactive ({Number(data?.quotes?.find(x => x?.type == "Universal")?.active).toLocaleString()})
                                      </Badge>{" "}
                                    </div>
                                  </div>
                                </div>
                              </Col>
                              <Col xl={4}>
                                <div>
                                  <div >
                                    <Row className="align-items-center">
                                      <Col xs={12}>
                                        <p className="mb-2">Country</p>
                                        <h4 className="mb-0">{Number(data?.quotes?.find(x => x?.type == "Country")?.total)?.toLocaleString()}</h4>
                                      </Col>
                                    </Row>
                                    <div className="mb-3 badge-list  mt-3 ">

                                      <Badge className="bg-success py-2">
                                        Active ({Number(data?.quotes?.find(x => x?.type == "Country")?.active).toLocaleString()})
                                      </Badge>{" "}

                                      <Badge className="bg-warning py-2">
                                        Inactive ({Number(data?.quotes?.find(x => x?.type == "Country")?.active).toLocaleString()})
                                      </Badge>{" "}
                                    </div>
                                  </div>
                                </div>
                              </Col>
                              <Col xl={4}>
                                <div>
                                  <div >
                                    <Row className="align-items-center">
                                      <Col xs={12}>
                                        <p className="mb-2">General</p>
                                        <h4 className="mb-0">{Number(data?.quotes?.find(x => x?.type == "General")?.total)?.toLocaleString()}</h4>
                                      </Col>

                                    </Row>
                                    <div className="mb-3 badge-list  mt-3 ">

                                      <Badge className="bg-success py-2">
                                        Active ({Number(data?.quotes?.find(x => x?.type == "General")?.active).toLocaleString()})
                                      </Badge>{" "}

                                      <Badge className="bg-warning py-2">
                                        Inactive ({Number(data?.quotes?.find(x => x?.type == "General")?.inActive).toLocaleString()})
                                      </Badge>{" "}
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg={6}>
                        <Card>
                          <CardBody>
                            <Row className="mt-4">
                              <Col lg={12}>
                                <h4 className="card-title mb-4"><i className="mdi mdi-soundcloud"></i> Harmony</h4>
                              </Col>
                              <Col xl={4}>
                                <div>
                                  <div >
                                    <Row className="align-items-center">
                                      <Col xs={12}>
                                        <p className="mb-2">Moods</p>
                                        <h4 className="mb-0">{Number(data?.harMood?.total).toLocaleString()}</h4>
                                      </Col>

                                    </Row>
                                    <div className="mb-3 badge-list  mt-3 ">

                                      <Badge className="bg-success py-2">
                                        Active ({Number(data?.harMood?.active).toLocaleString()})
                                      </Badge>{" "}

                                      <Badge className="bg-warning py-2">
                                        Inactive ({Number(data?.harMood?.inActive).toLocaleString()})
                                      </Badge>{" "}
                                    </div>
                                  </div>
                                </div>
                              </Col>
                              <Col xl={4}>
                                <div>
                                  <div >
                                    <Row className="align-items-center">
                                      <Col xs={12}>
                                        <p className="mb-2">Sound Category</p>
                                        <h4 className="mb-0">{Number(data?.harCat?.total).toLocaleString()}</h4>
                                      </Col>

                                    </Row>
                                    <div className="mb-3 badge-list  mt-3 ">

                                      <Badge className="bg-success py-2">
                                        Active ({Number(data?.harCat?.active).toLocaleString()})
                                      </Badge>{" "}

                                      <Badge className="bg-warning py-2">
                                        Inactive ({Number(data?.harCat?.inActive).toLocaleString()})
                                      </Badge>{" "}
                                    </div>
                                  </div>
                                </div>
                              </Col>
                              <Col xl={4}>
                                <div>
                                  <div >
                                    <Row className="align-items-center">
                                      <Col xs={12}>
                                        <p className="mb-2">Sounds</p>
                                        <h4 className="mb-0">{Number(data?.harSound?.total).toLocaleString()}</h4>
                                      </Col>

                                    </Row>
                                    <div className="mb-3 badge-list  mt-3 ">

                                      <Badge className="bg-success py-2">
                                        Active ({Number(data?.harSound?.active).toLocaleString()})
                                      </Badge>{" "}

                                      <Badge className="bg-warning py-2">
                                        Inactive ({Number(data?.harSound?.inActive).toLocaleString()})
                                      </Badge>{" "}
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    <React.Fragment>
                      <Row className='dashboard-card-box-section mt-4'>

                        {data?.cats?.map((item, index) => (
                          <Col lg={4} className="mb-4">
                            <Card className='card-section-dashboard' style={{ height: '100%' }}>
                              <CardBody>
                                <Row className="align-items-center">
                                  <div className="col-12">
                                    <div className="cat_icon">
                                      <img src={item?.image} />
                                    </div>
                                    <p className="mb-2">{item?.title}</p>
                                    <h4 className="mb-0">{Number(item?.total)?.toLocaleString()}</h4>
                                  </div>
                                  <div>
                                    <div className="mt-1 mb-3">
                                      <Progress
                                        value={String(((Number(item?.active) * 100) / Number(item?.total)).toFixed(0))}
                                        color="success"
                                        className="bg-transparent progress-sm"
                                        size="sm"
                                      />
                                    </div>
                                    <ul className="list_icon_box">
                                      <li>
                                        <div className="icon-name">
                                          <div className="avatar-xs font-size-14 ">
                                            <span className="avatar-title bg-soft-primary  rounded">
                                              <i className="mdi mdi-video"></i>
                                            </span>
                                          </div>
                                          <p>Video</p>
                                        </div>
                                        <h5>{Number(item?.video)?.toLocaleString()}</h5>
                                      </li>
                                      <li>
                                        <div className="icon-name">
                                          <div className="avatar-xs font-size-14 ">
                                            <span className="avatar-title bg-soft-primary  rounded">
                                              <i className="mdi mdi-audiobook"></i>
                                            </span>
                                          </div>
                                          <p>Audio</p>
                                        </div>
                                        <h5>{Number(item?.audio)?.toLocaleString()}</h5>
                                      </li>
                                      <li>
                                        <div className="icon-name">
                                          <div className="avatar-xs font-size-14 ">
                                            <span className="avatar-title bg-soft-primary  rounded">
                                              <i className="mdi mdi-clipboard-text"></i>
                                            </span>
                                          </div>
                                          <p>Text</p>
                                        </div>
                                        <h5>{Number(item?.text)?.toLocaleString()}</h5>
                                      </li>
                                      <li>
                                        <div className="icon-name">
                                          <div className="avatar-xs font-size-14 ">
                                            <span className="avatar-title bg-soft-primary  rounded">
                                              <i className="mdi mdi-file-image"></i>
                                            </span>
                                          </div>
                                          <p>Infographic</p>
                                        </div>
                                        <h5>{Number(item?.info)?.toLocaleString()}</h5>
                                      </li>
                                    </ul>
                                    <div className="mb-3 badge-list mt-3">
                                      <Badge className="bg-primary py-2">
                                        Active ({Number(item?.active)?.toLocaleString()})
                                      </Badge>{" "}
                                      <Badge className="bg-success py-2">
                                        Inactive ({Number(item?.inActive)?.toLocaleString()})
                                      </Badge>{" "}
                                      <Badge className="bg-info py-2">
                                        Paid ({Number(item?.paid)?.toLocaleString()})
                                      </Badge>{" "}
                                      <Badge className="bg-warning py-2">
                                        Unpaid ({Number(item?.unpaid)?.toLocaleString()})
                                      </Badge>{" "}
                                    </div>
                                    <div className="mt-1">
                                      <Link to="/content" className="btn btn_bg btn-sm">View <i className="mdi mdi-arrow-right ms-1" /></Link>
                                    </div>
                                  </div>
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        ))}

                        {/* <Col lg={4} className="mb-4">
                        <Card className='card-section-dashboard' style={{ height: '100%' }}>
                          <CardBody>
                            <Row className="align-items-center">
                              <div className="col-12">
                                <div className="cat_icon">
                                  <img src="https://beyondlifes.s3.ap-south-1.amazonaws.com/brain/harmony/1695190484601.svg" />
                                </div>
                                <p className="mb-2">Well Being</p>
                                <h4 className="mb-0">3,524</h4>
                              </div>
                              <div>
                                <div className="mt-1 mb-3">
                                  <Progress
                                    value="62"
                                    color="primary"
                                    className="bg-transparent progress-sm"
                                    size="sm"
                                  />
                                </div>
                                <ul className="list_icon_box">
                                  <li>
                                    <div className="icon-name">
                                      <div className="avatar-xs font-size-14 ">
                                        <span className="avatar-title bg-soft-primary  rounded">
                                          <i className="mdi mdi-video"></i>
                                        </span>
                                      </div>
                                      <p>Video</p>
                                    </div>
                                    <h5>20</h5>
                                  </li>
                                  <li>
                                    <div className="icon-name">
                                      <div className="avatar-xs font-size-14 ">
                                        <span className="avatar-title bg-soft-primary  rounded">
                                          <i className="mdi mdi-audiobook"></i>
                                        </span>
                                      </div>
                                      <p>Audio</p>
                                    </div>
                                    <h5>20</h5>
                                  </li>
                                  <li>
                                    <div className="icon-name">
                                      <div className="avatar-xs font-size-14 ">
                                        <span className="avatar-title bg-soft-primary  rounded">
                                          <i className="mdi mdi-clipboard-text"></i>
                                        </span>
                                      </div>
                                      <p>Text</p>
                                    </div>
                                    <h5>20</h5>
                                  </li>
                                  <li>
                                    <div className="icon-name">
                                      <div className="avatar-xs font-size-14 ">
                                        <span className="avatar-title bg-soft-primary  rounded">
                                          <i className="mdi mdi-file-image"></i>
                                        </span>
                                      </div>
                                      <p>Infographic</p>
                                    </div>
                                    <h5>20</h5>
                                  </li>
                                </ul>
                                <div className="mb-3 badge-list mt-3">
                                  <Badge className="bg-primary py-2">
                                    Active (20)
                                  </Badge>{" "}
                                  <Badge className="bg-success py-2">
                                    Inactive (30)
                                  </Badge>{" "}
                                  <Badge className="bg-info py-2">
                                    Paid (40)
                                  </Badge>{" "}
                                  <Badge className="bg-warning py-2">
                                    Unpaid (50)
                                  </Badge>{" "}
                                </div>
                                <div className="mt-1">
                                  <Link to="#" className="btn btn_bg btn-sm">View <i className="mdi mdi-arrow-right ms-1" /></Link>
                                </div>
                              </div>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg={4} className="mb-4">
                        <Card className='card-section-dashboard' style={{ height: '100%' }}>
                          <CardBody>
                            <Row className="align-items-center">
                              <div className="col-12">
                                <div className="cat_icon">
                                  <img src="https://beyondlifes.s3.ap-south-1.amazonaws.com/brain/harmony/1695190484601.svg" />
                                </div>
                                <p className="mb-2">Healthy Habits</p>
                                <h4 className="mb-0">3,524</h4>
                              </div>
                              <div>
                                <div className="mt-1 mb-3">
                                  <Progress
                                    value="62"
                                    color="warning"
                                    className="bg-transparent progress-sm"
                                    size="sm"
                                  />
                                </div>
                                <ul className="list_icon_box">
                                  <li>
                                    <div className="icon-name">
                                      <div className="avatar-xs font-size-14 ">
                                        <span className="avatar-title bg-soft-primary  rounded">
                                          <i className="mdi mdi-video"></i>
                                        </span>
                                      </div>
                                      <p>Video</p>
                                    </div>
                                    <h5>20</h5>
                                  </li>
                                  <li>
                                    <div className="icon-name">
                                      <div className="avatar-xs font-size-14 ">
                                        <span className="avatar-title bg-soft-primary  rounded">
                                          <i className="mdi mdi-audiobook"></i>
                                        </span>
                                      </div>
                                      <p>Audio</p>
                                    </div>
                                    <h5>20</h5>
                                  </li>
                                  <li>
                                    <div className="icon-name">
                                      <div className="avatar-xs font-size-14 ">
                                        <span className="avatar-title bg-soft-primary  rounded">
                                          <i className="mdi mdi-clipboard-text"></i>
                                        </span>
                                      </div>
                                      <p>Text</p>
                                    </div>
                                    <h5>20</h5>
                                  </li>
                                  <li>
                                    <div className="icon-name">
                                      <div className="avatar-xs font-size-14 ">
                                        <span className="avatar-title bg-soft-primary  rounded">
                                          <i className="mdi mdi-file-image"></i>
                                        </span>
                                      </div>
                                      <p>Infographic</p>
                                    </div>
                                    <h5>20</h5>
                                  </li>
                                </ul>
                                <div className="mb-3 badge-list mt-3">
                                  <Badge className="bg-primary py-2">
                                    Active (20)
                                  </Badge>{" "}
                                  <Badge className="bg-success py-2">
                                    Inactive (30)
                                  </Badge>{" "}
                                  <Badge className="bg-info py-2">
                                    Paid (40)
                                  </Badge>{" "}
                                  <Badge className="bg-warning py-2">
                                    Unpaid (50)
                                  </Badge>{" "}
                                </div>
                                <div className="mt-1">
                                  <Link to="#" className="btn btn_bg btn-sm">View <i className="mdi mdi-arrow-right ms-1" /></Link>
                                </div>
                              </div>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg={4} className="mb-4">
                        <Card className='card-section-dashboard' style={{ height: '100%' }}>
                          <CardBody>
                            <Row className="align-items-center">
                              <div className="col-12">
                                <div className="cat_icon">
                                  <img src="https://beyondlifes.s3.ap-south-1.amazonaws.com/brain/harmony/1695190484601.svg" />
                                </div>
                                <p className="mb-2">Astro Life</p>
                                <h4 className="mb-0">3,524</h4>
                              </div>
                              <div>
                                <div className="mt-1 mb-3">
                                  <Progress
                                    value="62"
                                    color="success"
                                    className="bg-transparent progress-sm"
                                    size="sm"
                                  />
                                </div>
                                <ul className="list_icon_box">
                                  <li>
                                    <div className="icon-name">
                                      <div className="avatar-xs font-size-14 ">
                                        <span className="avatar-title bg-soft-primary  rounded">
                                          <i className="mdi mdi-video"></i>
                                        </span>
                                      </div>
                                      <p>Video</p>
                                    </div>
                                    <h5>20</h5>
                                  </li>
                                  <li>
                                    <div className="icon-name">
                                      <div className="avatar-xs font-size-14 ">
                                        <span className="avatar-title bg-soft-primary  rounded">
                                          <i className="mdi mdi-audiobook"></i>
                                        </span>
                                      </div>
                                      <p>Audio</p>
                                    </div>
                                    <h5>20</h5>
                                  </li>
                                  <li>
                                    <div className="icon-name">
                                      <div className="avatar-xs font-size-14 ">
                                        <span className="avatar-title bg-soft-primary  rounded">
                                          <i className="mdi mdi-clipboard-text"></i>
                                        </span>
                                      </div>
                                      <p>Text</p>
                                    </div>
                                    <h5>20</h5>
                                  </li>
                                  <li>
                                    <div className="icon-name">
                                      <div className="avatar-xs font-size-14 ">
                                        <span className="avatar-title bg-soft-primary  rounded">
                                          <i className="mdi mdi-file-image"></i>
                                        </span>
                                      </div>
                                      <p>Infographic</p>
                                    </div>
                                    <h5>20</h5>
                                  </li>
                                </ul>
                                <div className="mb-3 badge-list mt-3">
                                  <Badge className="bg-primary py-2">
                                    Active (20)
                                  </Badge>{" "}
                                  <Badge className="bg-success py-2">
                                    Inactive (30)
                                  </Badge>{" "}
                                  <Badge className="bg-info py-2">
                                    Paid (40)
                                  </Badge>{" "}
                                  <Badge className="bg-warning py-2">
                                    Unpaid (50)
                                  </Badge>{" "}
                                </div>
                                <div className="mt-1">
                                  <Link to="#" className="btn btn_bg btn-sm">View <i className="mdi mdi-arrow-right ms-1" /></Link>
                                </div>
                              </div>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg={4} className="mb-4">
                        <Card className='card-section-dashboard' style={{ height: '100%' }}>
                          <CardBody>
                            <Row className="align-items-center">
                              <div className="col-12">
                                <div className="cat_icon">
                                  <img src="https://beyondlifes.s3.ap-south-1.amazonaws.com/brain/harmony/1695190484601.svg" />
                                </div>
                                <p className="mb-2">Kids Wellness</p>
                                <h4 className="mb-0">3,524</h4>
                              </div>
                              <div>
                                <div className="mt-1 mb-3">
                                  <Progress
                                    value="62"
                                    color="info"
                                    className="bg-transparent progress-sm"
                                    size="sm"
                                  />
                                </div>
                                <ul className="list_icon_box">
                                  <li>
                                    <div className="icon-name">
                                      <div className="avatar-xs font-size-14 ">
                                        <span className="avatar-title bg-soft-primary  rounded">
                                          <i className="mdi mdi-video"></i>
                                        </span>
                                      </div>
                                      <p>Video</p>
                                    </div>
                                    <h5>20</h5>
                                  </li>
                                  <li>
                                    <div className="icon-name">
                                      <div className="avatar-xs font-size-14 ">
                                        <span className="avatar-title bg-soft-primary  rounded">
                                          <i className="mdi mdi-audiobook"></i>
                                        </span>
                                      </div>
                                      <p>Audio</p>
                                    </div>
                                    <h5>20</h5>
                                  </li>
                                  <li>
                                    <div className="icon-name">
                                      <div className="avatar-xs font-size-14 ">
                                        <span className="avatar-title bg-soft-primary  rounded">
                                          <i className="mdi mdi-clipboard-text"></i>
                                        </span>
                                      </div>
                                      <p>Text</p>
                                    </div>
                                    <h5>20</h5>
                                  </li>
                                  <li>
                                    <div className="icon-name">
                                      <div className="avatar-xs font-size-14 ">
                                        <span className="avatar-title bg-soft-primary  rounded">
                                          <i className="mdi mdi-file-image"></i>
                                        </span>
                                      </div>
                                      <p>Infographic</p>
                                    </div>
                                    <h5>20</h5>
                                  </li>
                                </ul>
                                <div className="mb-3 badge-list mt-3">
                                  <Badge className="bg-primary py-2">
                                    Active (20)
                                  </Badge>{" "}
                                  <Badge className="bg-success py-2">
                                    Inactive (30)
                                  </Badge>{" "}
                                  <Badge className="bg-info py-2">
                                    Paid (40)
                                  </Badge>{" "}
                                  <Badge className="bg-warning py-2">
                                    Unpaid (50)
                                  </Badge>{" "}
                                </div>
                                <div className="mt-1">
                                  <Link to="#" className="btn btn_bg btn-sm">View <i className="mdi mdi-arrow-right ms-1" /></Link>
                                </div>
                              </div>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg={4} className="mb-4">
                        <Card className='card-section-dashboard' style={{ height: '100%' }}>
                          <CardBody>
                            <Row className="align-items-center">
                              <div className="col-12">
                                <div className="cat_icon">
                                  <img src="https://beyondlifes.s3.ap-south-1.amazonaws.com/brain/harmony/1695190484601.svg" />
                                </div>
                                <p className="mb-2">Lifestyle Practices</p>
                                <h4 className="mb-0">3,524</h4>
                              </div>
                              <div>
                                <div className="mt-1 mb-3">
                                  <Progress
                                    value="62"
                                    color="secondary"
                                    className="bg-transparent progress-sm"
                                    size="sm"
                                  />
                                </div>
                                <ul className="list_icon_box">
                                  <li>
                                    <div className="icon-name">
                                      <div className="avatar-xs font-size-14 ">
                                        <span className="avatar-title bg-soft-primary  rounded">
                                          <i className="mdi mdi-video"></i>
                                        </span>
                                      </div>
                                      <p>Video</p>
                                    </div>
                                    <h5>20</h5>
                                  </li>
                                  <li>
                                    <div className="icon-name">
                                      <div className="avatar-xs font-size-14 ">
                                        <span className="avatar-title bg-soft-primary  rounded">
                                          <i className="mdi mdi-audiobook"></i>
                                        </span>
                                      </div>
                                      <p>Audio</p>
                                    </div>
                                    <h5>20</h5>
                                  </li>
                                  <li>
                                    <div className="icon-name">
                                      <div className="avatar-xs font-size-14 ">
                                        <span className="avatar-title bg-soft-primary  rounded">
                                          <i className="mdi mdi-clipboard-text"></i>
                                        </span>
                                      </div>
                                      <p>Text</p>
                                    </div>
                                    <h5>20</h5>
                                  </li>
                                  <li>
                                    <div className="icon-name">
                                      <div className="avatar-xs font-size-14 ">
                                        <span className="avatar-title bg-soft-primary  rounded">
                                          <i className="mdi mdi-file-image"></i>
                                        </span>
                                      </div>
                                      <p>Infographic</p>
                                    </div>
                                    <h5>20</h5>
                                  </li>
                                </ul>
                                <div className="mb-3 badge-list mt-3">
                                  <Badge className="bg-primary py-2">
                                    Active (20)
                                  </Badge>{" "}
                                  <Badge className="bg-success py-2">
                                    Inactive (30)
                                  </Badge>{" "}
                                  <Badge className="bg-info py-2">
                                    Paid (40)
                                  </Badge>{" "}
                                  <Badge className="bg-warning py-2">
                                    Unpaid (50)
                                  </Badge>{" "}
                                </div>
                                <div className="mt-1">
                                  <Link to="#" className="btn btn_bg btn-sm">View <i className="mdi mdi-arrow-right ms-1" /></Link>
                                </div>
                              </div>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col> */}
                      </Row>
                    </React.Fragment>
                  </Row>
                </TabPane>
                <TabPane tabId="3">
                  <Row className="pt-3">
                    <Col lg={12}>
                      <h4 className="card-title-d mb-4">Subscription Management</h4>
                    </Col>
                    <Col xl={12}>
                      <SubsWidget users={data?.users} />
                    </Col>
                  </Row>
                  <Row className="mt-4 mx-1">
                    <Card className='card-section-dashboard card-section-dashboard-box ' style={{ height: '95%' }}>
                      <CardBody>
                        <Label>Renewed Users</Label>
                        <SubsChart data={data?.compareChart} />
                      </CardBody>
                    </Card>
                  </Row>
                  <Row className="mt-4">
                    <Col md={3}>
                      <Card className='card-section-dashboard card-section-dashboard-box ' style={{ height: '100%' }}>
                        <CardBody>
                          <Label>Total Used Plans So Far</Label>
                          <UsedChart data={data?.usedPlan} />
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={3}>
                      <Card className='card-section-dashboard card-section-dashboard-box ' style={{ height: '100%' }}>
                        <CardBody>
                          <Label>Total Renewed Plans So Far</Label>
                          <PlanChart data={data?.plan} />
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={3}>
                      <Card className='card-section-dashboard card-section-dashboard-box ' style={{ height: '100%' }}>
                        <CardBody>
                          <Label>Total Expired Plans So Far</Label>
                          <ExpireChart data={data?.expired} />
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg={3}>
                      <Card className="bg-primary" style={{ height: '93%' }}>
                        <CardBody>
                          {/* <div className="text-white-50">
                          <h5 className="text-white">2400 + New Users</h5>
                          <p>At vero eos et accusamus et iusto odio dignissimos ducimus</p>
                          <div>
                            <Link to="#" className="btn btn-outline-success btn-sm">View more</Link>
                          </div>
                        </div> */}
                          <Row className="justify-content-end">
                            <div className="col-12">
                              <div className="mt-4">
                                <img src={widgetImage} alt=""
                                  className="img-fluid mx-auto d-block" />
                              </div>
                            </div>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="4">
                  <Setting user={user} />
                </TabPane>
                <TabPane tabId="5">
                  <Row className="pt-3 content-section-dashboard">
                    <Col lg={12}>
                      <h4 className="card-title-d mb-4">Watched History</h4>
                    </Col>
                    <Col md={4}>
                      <PodcastRequest data={data?.podcastRequest} />
                    </Col>
                    <Col md={4}>
                      <TTMRequest data={data?.tTMReq} />
                    </Col>
                    <Col md={4}>
                      <RecentNoti data={data?.notifications} />
                    </Col>
                    <Col md={4}>
                      <ThemeChart data={data?.theme} />
                    </Col>
                    <Col md={4}>
                      <WellnessChart data={data?.wellness} />
                    </Col>
                    <Col md={4}>
                      <Row className='dashboard-card-box-section'>
                        <Col lg={6}>
                          <Card className='card-section-dashboard' style={{ height: '100%' }}>
                            <CardBody>
                              <Row className="align-items-center">
                                <div className="col-12">
                                  <p className="mb-2">Know Yourself (Attempt)</p>
                                  <h4 className="mb-0">{Number(data?.knowAns?.total).toLocaleString()}</h4>
                                </div>
                                <div>
                                  <div className="mt-1 mb-3">
                                    <Progress
                                      value="100"
                                      color="success"
                                      className="bg-transparent progress-sm"
                                      size="sm"
                                    />
                                  </div>
                                  <div className="mt-1">
                                    <Link to="/users" className="btn btn_bg btn-sm">View <i className="mdi mdi-arrow-right ms-1" /></Link>
                                  </div>
                                </div>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col lg={6}>
                          <Card className='card-section-dashboard' style={{ height: '100%' }}>
                            <CardBody>
                              <Row className="align-items-center">
                                <div className="col-12">
                                  <p className="mb-2">Community</p>
                                  <h4 className="mb-0">{Number(12).toLocaleString()}</h4>
                                </div>
                                <div>
                                  <div className="mt-1 mb-3">
                                    <Progress
                                      value={String(((Number(12) * 100) / Number(12)).toFixed(0))}
                                      color="primary"
                                      className="bg-transparent progress-sm"
                                      size="sm"
                                    />
                                  </div>
                                  <div className="mt-1">
                                    <Link to="/user/unregistered" className="btn btn_bg btn-sm">View <i className="mdi mdi-arrow-right ms-1" /></Link>
                                  </div>
                                </div>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col md={12}>
                          <Card className="mt-4">
                            <CardBody>
                              <Label>Yoga Watched</Label>
                              {data?.yoga?.length ?
                                <Row className="mt-2">
                                  {data?.yoga?.map((item, index) => (
                                    <Col md={3} className="mt-4">
                                      <YogaChart title={item?.title} value={item?.clicks} readOnly fgColor={darkColors[index]} />
                                    </Col>
                                  ))}
                                </Row>
                                : null}
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={12}>
                      <PodcastChart data={data?.podcast} />
                    </Col>
                  </Row>
                  {data?.content?.length ?
                    <Row>
                      {data?.content?.map((item, index) => (
                        <Col md={4}>
                          <Card className='card-section-dashboard'>
                            <CardBody>
                              <CategoryChart title={item?.title} data={item?.content} />
                            </CardBody>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                    : null}
                  {data?.content?.length ?
                    <Row>
                      {data?.content?.map((item, index) => (
                        <Col md={12}>
                          <ContentChart title={item?.title} image={item?.image} data={item?.content} />
                        </Col>
                      ))}
                    </Row>
                    : null}
                </TabPane>
              </TabContent>

            </Col>
          </Row>
          : null}
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  getProfile: PropTypes.func,
  showRightSidebarAction: PropTypes.func,
}

const mapStateToProps = state => {
  // console.log('state at dashboard', state);
  const { user } = state.Profile;
  return { user }
}

export default withRouter(
  connect(mapStateToProps, { getProfile, showRightSidebarAction })(Dashboard)
)