import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Row, Progress, Col } from 'reactstrap';

const MiniWidget = ({ users }) => {

    return (
        <React.Fragment>
            <Row className='dashboard-card-box-section'>
                <Col lg={2}>
                    <Card className='card-section-dashboard' style={{ height: '100%' }}>
                        <CardBody>
                            <Row className="align-items-center">
                                <div className="col-12">
                                    <p className="mb-2">All Users</p>
                                    <h4 className="mb-0">{Number(users?.allUser).toLocaleString()}</h4>
                                </div>
                                <div>
                                    <div className="mt-1 mb-3">
                                        <Progress
                                            value="100"
                                            color="success"
                                            className="bg-transparent progress-sm"
                                            size="sm"
                                        />
                                    </div>
                                    <div className="mt-1">
                                        <Link to="/all_users" className="btn btn_bg btn-sm">View <i className="mdi mdi-arrow-right ms-1" /></Link>
                                    </div>
                                </div>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={2}>
                    <Card className='card-section-dashboard' style={{ height: '100%' }}>
                        <CardBody>
                            <Row className="align-items-center">
                                <div className="col-12">
                                    <p className="mb-2">Unregistered Users</p>
                                    <h4 className="mb-0">{Number(users?.unregistered).toLocaleString()}</h4>
                                </div>
                                <div>
                                    <div className="mt-1 mb-3">
                                        <Progress
                                            value={String(((Number(users?.unregistered) * 100) / Number(users?.allUser)).toFixed(0))}
                                            color="primary"
                                            className="bg-transparent progress-sm"
                                            size="sm"
                                        />
                                    </div>
                                    <div className="mt-1">
                                        <Link to="/user/unregistered" className="btn btn_bg btn-sm">View <i className="mdi mdi-arrow-right ms-1" /></Link>
                                    </div>
                                </div>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={2}>
                    <Card className='card-section-dashboard' style={{ height: '100%' }}>
                        <CardBody>
                            <Row className="align-items-center">
                                <div className="col-12">
                                    <p className="mb-2">Unregistered to Registered</p>
                                    <h4 className="mb-0">{Number(users?.utr).toLocaleString()}</h4>
                                </div>
                                <div>
                                    <div className="mt-1 mb-3">
                                        <Progress
                                            value={String(((Number(users?.utr) * 100) / Number(users?.allUser)).toFixed(0))}
                                            color="warning"
                                            className="bg-transparent progress-sm"
                                            size="sm"
                                        />
                                    </div>
                                    <div className="mt-1">
                                        <Link to="/user/unregistered-to-registered" className="btn btn_bg btn-sm">View <i className="mdi mdi-arrow-right ms-1" /></Link>
                                    </div>
                                </div>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={2}>
                    <Card className='card-section-dashboard' style={{ height: '100%' }}>
                        <CardBody>
                            <Row className="align-items-center">
                                <div className="col-12">
                                    <p className="mb-2">Registered Users</p>
                                    <h4 className="mb-0">{Number(users?.registered).toLocaleString()}</h4>
                                </div>
                                <div>
                                    <div className="mt-1 mb-3">
                                        <Progress
                                            value={String(((Number(users?.registered) * 100) / Number(users?.allUser)).toFixed(0))}
                                            color="success"
                                            className="bg-transparent progress-sm"
                                            size="sm"
                                        />
                                    </div>
                                    <div className="mt-1">
                                        <Link to="/user/registered" className="btn btn_bg btn-sm">View <i className="mdi mdi-arrow-right ms-1" /></Link>
                                    </div>
                                </div>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={2}>
                    <Card className='card-section-dashboard' style={{ height: '100%' }}>
                        <CardBody>
                            <Row className="align-items-center">
                                <div className="col-12">
                                    <p className="mb-2">Registered to Paid</p>
                                    <h4 className="mb-0">{Number(users?.rtp).toLocaleString()}</h4>
                                </div>
                                <div>
                                    <div className="mt-1 mb-3">
                                        <Progress
                                            value={String(((Number(users?.rtp) * 100) / Number(users?.allUser)).toFixed(0))}
                                            color="info"
                                            className="bg-transparent progress-sm"
                                            size="sm"
                                        />
                                    </div>
                                    <div className="mt-1">
                                        <Link to="/user/registered-to-paid" className="btn btn_bg btn-sm">View <i className="mdi mdi-arrow-right ms-1" /></Link>
                                    </div>
                                </div>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={2}>
                    <Card className='card-section-dashboard' style={{ height: '100%' }}>
                        <CardBody>
                            <Row className="align-items-center">
                                <div className="col-12">
                                    <p className="mb-2">Paid Users</p>
                                    <h4 className="mb-0">{Number(users?.paid).toLocaleString()}</h4>
                                </div>
                                <div>
                                    <div className="mt-1 mb-3">
                                        <Progress
                                            value={String(((Number(users?.paid) * 100) / Number(users?.allUser)).toFixed(0))}
                                            color="secondary"
                                            className="bg-transparent progress-sm"
                                            size="sm"
                                        />
                                    </div>
                                    <div className="mt-1">
                                        <Link to="/user/paid" className="btn btn_bg btn-sm">View <i className="mdi mdi-arrow-right ms-1" /></Link>
                                    </div>
                                </div>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default MiniWidget;