import PropTypes from "prop-types"
import React, { useEffect, useState, useRef, useCallback } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom";
import Utils from '../../pages/Utility';
import { get } from "../../helpers/api_helper";

//i18n
import { withTranslation } from "react-i18next";

// redux
import { connect } from "react-redux";
import { getNotiSuccess, loginSuccess } from "../../store/actions";

const SidebarContent = props => {
  // console.log('props on slidebar content', props?.user);
  const [permission, setPermissions] = useState([]);

  const ref = useRef()
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname
    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        // console.log("initing mennu", items[i].pathname, pathName);
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
      // console.log("initing mennu", matchingMenuItem);
    }
    initMenu();
  }, [props.location, props?.user?.role]);

  useEffect(() => {
    if (props?.user?.permissions?.length);
    setPermissions(props?.user?.permissions);
  }, [props?.user]);

  // console.log("user on slidebar content", props?.user);

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    item.style.color = Utils.themeColor;
    item.style.fontWeight = 'bold';
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false
    }
    scrollElement(item);
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar ref={ref} className="vertical-simplebar">
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {/* <li className="menu-title">{props.t("Menu")} </li> */}
            <li>
              <Link to="/dashboard" className="waves-effect">
                <i className="mdi mdi-airplay" style={{ color: Utils.themeColor }}></i>
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>
            {props.user?.role == 'Admin' ?
              <>
                <li>
                  <Link to={"/#"} className={"has-arrow waves-effect"}>
                    <i className="mdi mdi-account" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Users Management")}</span>
                  </Link>
                  <ul className={"sub-menu"}>
                    <li><Link to="/users">{props.t("All Users")}</Link></li>
                    <li><Link to="/user/unregistered">{props.t("Unregistered Users")}</Link></li>
                    <li><Link to="/user/unregistered-to-registered">{props.t("Unregistered to Registered")}</Link></li>
                    <li><Link to="/user/registered">{props.t("Registered Users")}</Link></li>
                    <li><Link to="/user/registered-to-paid">{props.t("Registered to Paid")}</Link></li>
                    <li><Link to="/user/paid">{props.t("Paid Users")}</Link></li>
                  </ul>
                </li>
                <li>
                  <Link to={"/#"} className={"has-arrow waves-effect"}>
                    <i className="mdi mdi-account" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Renewal Base")}</span>
                  </Link>
                  <ul className={"sub-menu"}>
                    <li><Link to="/user/all-paid">{props.t("All")}</Link></li>
                    <li><Link to="/user/renewed">{props.t("Renewed")}</Link></li>
                    <li><Link to="/user/renew-in-15">{props.t("15 Days to Renew")}</Link></li>
                    <li><Link to="/user/renew-in-5">{props.t("5 Days to Renew")}</Link></li>
                    <li><Link to="/user/renew-due-5">{props.t("5 Days not Renewed")}</Link></li>
                    <li><Link to="/user/renew-due-15">{props.t("15 Days not Renewed")}</Link></li>
                  </ul>
                </li>
                <li>
                  <Link to={"/#"} className={"has-arrow waves-effect"}>
                    <i className="mdi mdi-format-quote-close" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Quotes")}</span>
                  </Link>
                  <ul className={"sub-menu"}>
                    {/* <li><Link to="/quotes/tags">{props.t("Tags")}</Link></li> */}
                    <li><Link to="/quotes/universal">{props.t("Universal")}</Link></li>
                    <li><Link to="/quotes/country">{props.t("Country")}</Link></li>
                    <li><Link to="/quotes/general">{props.t("General")}</Link></li>
                  </ul>
                </li>
                <li>
                  <Link to="/theme" className="waves-effect">
                    <i className="mdi mdi-compare" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Theme")}</span>
                  </Link>
                </li>
                {/* <li>
                  <Link to={"/#"} className={"has-arrow waves-effect"}>
                    <i className="mdi mdi-compare" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Theme")}</span>
                  </Link>
                  <ul className={"sub-menu"}>
                    <li><Link to="/weather">{props.t("Weather")}</Link></li>
                    <li><Link to="/theme">{props.t("Theme")}</Link></li>
                  </ul>
                </li> */}
                <li>
                  <Link to="/popup" className="waves-effect">
                    <i className="mdi mdi-cellphone-screenshot" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Popup")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/wellness" className="waves-effect">
                    <i className="mdi mdi-meditation" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Wellness")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/yoga" className="waves-effect">
                    <i className="mdi mdi-yoga" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Yoga")}</span>
                  </Link>
                </li>
                <li>
                  <Link to={"/#"} className={"has-arrow waves-effect"}>
                    <i className="mdi mdi-soundcloud" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Harmony")}</span>
                  </Link>
                  <ul className={"sub-menu"}>
                    <li><Link to="/harmony_mood">{props.t("Mood")}</Link></li>
                    <li><Link to="/harmony_category">{props.t("Sound Category")}</Link></li>
                    <li><Link to="/harmony_sound">{props.t("Sounds")}</Link></li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="mdi mdi-account-star" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Know Yourself")}</span>
                  </Link>
                  <ul className={"sub-menu"}>
                    <li><Link to="/know-yourself-category">{props.t("Category")}</Link></li>
                    <li><Link to="/know-yourself-intro">{props.t("Introduction")}</Link></li>
                    <li><Link to="/know-yourself-question">{props.t("Questions")}</Link></li>
                    {/* <li><Link to="/know-yourself-question">{props.t("Answer")}</Link></li> */}
                  </ul>
                </li>
                <li>
                  <Link to="/category" className="waves-effect">
                    <i className="mdi mdi-shape" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Category")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/tags" className="waves-effect">
                    <i className="mdi mdi-tag-multiple" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Tags")}</span>
                  </Link>
                </li>
                {/* <li>
                  <Link to="/store" className="waves-effect">
                    <i className="mdi mdi-image-multiple" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Image Store")}</span>
                  </Link>
                </li> */}
                <li>
                  <Link to="/content" className="waves-effect">
                    <i className="mdi mdi-table-of-contents" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Content")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/games" className="waves-effect">
                    <i className="mdi mdi-gamepad" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Games")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/communities" className="waves-effect">
                    <i className="mdi mdi-home-group" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Communities")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="mdi mdi-account-star" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Talk To Me")}</span>
                  </Link>
                  <ul className={"sub-menu"}>
                    <li><Link to="/talk-intro">{props.t("Introduction")}</Link></li>
                    {/* <li><Link to="/talk-category">{props.t("Category")}</Link></li> */}
                    <li><Link to="/talk-ques">{props.t("Question")}</Link></li>
                    <li><Link to="/talks">{props.t("Sessions")}</Link></li>
                  </ul>
                </li>
                <li>
                  <Link to="/live_session" className="waves-effect">
                    <i className="mdi mdi-youtube-tv" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Live Session")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="mdi mdi-cast-audio" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Podcast")}</span>
                  </Link>
                  <ul className={"sub-menu"}>
                    <li><Link to="/podcast">{props.t("Podcasts")}</Link></li>
                    <li><Link to="/podcast_request">{props.t("Podcast Request")}</Link></li>
                  </ul>
                </li>
                <li>
                  <Link to="/plans" className="waves-effect">
                    <i className="mdi mdi-currency-usd" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Plans")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/coupon" className="waves-effect">
                    <i className="mdi mdi-ticket-percent" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Coupon")}</span>
                  </Link>
                </li>
                {/* <li>
                  <Link to="/social-media" className="waves-effect">
                    <i className="mdi mdi-play-network" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Social-Media")}</span>
                  </Link>
                </li> */}
                {/* <li>
                  <Link to="/setting" className="waves-effect">
                    <i className="mdi mdi-cogs" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Settings")}</span>
                  </Link>
                </li> */}
                {/* <li>
                  <Link to="/screen" className="waves-effect">
                    <i className="mdi mdi-cellphone-text" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("App Screens")}</span>
                  </Link>
                </li> */}
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="mdi mdi-account-star" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Blog")}</span>
                  </Link>
                  <ul className={"sub-menu"}>
                    <li><Link to="/blog">{props.t("Blog")}</Link></li>
                    <li><Link to="/blog-category">{props.t("Category")}</Link></li>

                  </ul>
                </li>
                <li>
                  <Link to="/notification" className="waves-effect">
                    <i className="mdi mdi-bell" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Notifications")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="mdi mdi-cogs" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Others")}</span>
                  </Link>
                  <ul className={"sub-menu"}>
                    <li><Link to="/languages">{props.t("Languages")}</Link></li>
                    <li><Link to="/social-media">{props.t("Social-Media")}</Link></li>
                    <li><Link to="/store">{props.t("Image Store")}</Link></li>
                    <li><Link to="/mailer">{props.t("Mails")}</Link></li>
                    <li><Link to="/sitemap">{props.t("Sitemap")}</Link></li>
                    <li><Link to="/screen">{props.t("App Screens")}</Link></li>
                    <li><Link to="/sub-admin">{props.t("Sub-Admin")}</Link></li>
                  </ul>
                </li>
                {/* <li>
                  <Link to="/mailer" className="waves-effect">
                    <i className="mdi mdi-email-edit-outline" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Mails")}</span>
                  </Link>
                </li> */}
                {/* <li>
                  <Link to="/sub-admin" className="waves-effect">
                    <i className="mdi mdi-key" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Sub-Admin")}</span>
                  </Link>
                </li> */}
                {/* <li>
                  <Link to="/sitemap" className="waves-effect">
                    <i className="mdi mdi-sitemap" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Sitemap")}</span>
                  </Link>
                </li> */}
              </>
              :
              <>
                {permission?.length && permission[0]?.user?.read &&
                  <li>
                    <Link to="/users" className="waves-effect">
                      <i className="mdi mdi-account-multiple" style={{ color: Utils.themeColor }}></i>
                      <span>{props.t("Users")}</span>
                    </Link>
                  </li>
                }
                {/* {permission?.length && permission[1]?.language?.read &&
                  <li>
                    <Link to="/language" className="waves-effect">
                      <i className="mdi mdi-translate" style={{ color: Utils.themeColor }}></i>
                      <span>{props.t("Languages")}</span>
                    </Link>
                  </li>
                } */}
                {permission?.length && permission[2]?.quote?.read &&
                  <li>
                    <Link to={"/#"} className={"has-arrow waves-effect"} >
                      <i className="mdi mdi-format-quote-close" style={{ color: Utils.themeColor }}></i>
                      <span>{props.t("Quotes")}</span>
                    </Link>
                    <ul className={"sub-menu"}>
                      {/* <li><Link to="/quotes/tags">{props.t("Tags")}</Link></li> */}
                      <li><Link to="/quotes/universal">{props.t("Universal")}</Link></li>
                      <li><Link to="/quotes/country">{props.t("Country")}</Link></li>
                      <li><Link to="/quotes/general">{props.t("General")}</Link></li>
                    </ul>
                  </li>
                }
                {permission?.length && permission[3]?.theme?.read &&
                  <li>
                    <Link to={"/#"} className={"has-arrow waves-effect"}>
                      <i className="mdi mdi-compare" style={{ color: Utils.themeColor }}></i>
                      <span>{props.t("Theme")}</span>
                    </Link>
                    <ul className={"sub-menu"}>
                      <li><Link to="/weather">{props.t("Weather")}</Link></li>
                      <li><Link to="/theme">{props.t("Theme")}</Link></li>
                    </ul>
                  </li>
                }
                {permission?.length && permission[4]?.wellness?.read &&
                  <li>
                    <Link to="/wellness" className="waves-effect">
                      <i className="mdi mdi-meditation" style={{ color: Utils.themeColor }}></i>
                      <span>{props.t("Wellness")}</span>
                    </Link>
                  </li>
                }
                {permission?.length && permission[5]?.yoga?.read &&
                  <li>
                    <Link to="/yoga" className="waves-effect">
                      <i className="mdi mdi-yoga" style={{ color: Utils.themeColor }}></i>
                      <span>{props.t("Yoga")}</span>
                    </Link>
                  </li>
                }
                {permission?.length && permission[6]?.harmony?.read &&
                  <li>
                    <Link to={"/#"} className={"has-arrow waves-effect"}>
                      <i className="mdi mdi-soundcloud" style={{ color: Utils.themeColor }}></i>
                      <span>{props.t("Harmony")}</span>
                    </Link>
                    <ul className={"sub-menu"}>
                      <li><Link to="/harmony_mood">{props.t("Mood")}</Link></li>
                      <li><Link to="/harmony_category">{props.t("Sound Category")}</Link></li>
                      <li><Link to="/harmony_sound">{props.t("Sounds")}</Link></li>
                    </ul>
                  </li>
                }
                {permission?.length && permission[7]?.knowyourself?.read &&
                  <li>
                    <Link to="/#" className="has-arrow waves-effect">
                      <i className="mdi mdi-account-star" style={{ color: Utils.themeColor }}></i>
                      <span>{props.t("Know Yourself")}</span>
                    </Link>
                    <ul className={"sub-menu"}>
                      <li><Link to="/know-yourself-category">{props.t("Category")}</Link></li>
                      {permission?.length && permission[7]?.knowyourself?.update &&
                        <li><Link to="/know-yourself-intro">{props.t("Introduction")}</Link></li>
                      }
                      <li><Link to="/know-yourself-question">{props.t("Questions")}</Link></li>
                    </ul>
                  </li>
                }
                {permission?.length && permission[8]?.category?.read &&
                  <li>
                    <Link to="/category" className="waves-effect">
                      <i className="mdi mdi-shape" style={{ color: Utils.themeColor }}></i>
                      <span>{props.t("Category")}</span>
                    </Link>
                  </li>
                }
                {permission?.length && permission[9]?.tags?.read &&
                  <li>
                    <Link to="/tags" className="waves-effect">
                      <i className="mdi mdi-tag-multiple" style={{ color: Utils.themeColor }}></i>
                      <span>{props.t("Tags")}</span>
                    </Link>
                  </li>
                }
                {permission?.length && permission[10]?.content?.read &&
                  <li>
                    <Link to="/content" className="waves-effect">
                      <i className="mdi mdi-table-of-contents" style={{ color: Utils.themeColor }}></i>
                      <span>{props.t("Content")}</span>
                    </Link>
                  </li>
                }
                {permission?.length && permission[11]?.games?.read &&
                  <li>
                    <Link to="/games" className="waves-effect">
                      <i className="mdi mdi-gamepad" style={{ color: Utils.themeColor }}></i>
                      <span>{props.t("Games")}</span>
                    </Link>
                  </li>
                }
                {permission?.length && permission[12]?.communities?.read &&
                  <li>
                    <Link to="/communities" className="waves-effect">
                      <i className="mdi mdi-home-group" style={{ color: Utils.themeColor }}></i>
                      <span>{props.t("Communities")}</span>
                    </Link>
                  </li>
                }
                {permission?.length && permission[13]?.talk_to_me?.read &&
                  <li>
                    <Link to="/#" className="has-arrow waves-effect">
                      <i className="mdi mdi-account-star" style={{ color: Utils.themeColor }}></i>
                      <span>{props.t("Talk To Me")}</span>
                    </Link>
                    <ul className={"sub-menu"}>
                      {permission?.length && permission[13]?.talk_to_me?.update &&
                        <li><Link to="/talk-intro">{props.t("Introduction")}</Link></li>
                      }
                      <li><Link to="/talk-category">{props.t("Category")}</Link></li>
                      <li><Link to="/talk-ques">{props.t("Question")}</Link></li>
                      <li><Link to="/talks">{props.t("Sessions")}</Link></li>
                    </ul>
                  </li>
                }
                {permission?.length && permission[14]?.live_session?.read &&
                  <li>
                    <Link to="/live_session" className="waves-effect">
                      <i className="mdi mdi-youtube-tv" style={{ color: Utils.themeColor }}></i>
                      <span>{props.t("Live Session")}</span>
                    </Link>
                  </li>
                }
                {permission?.length && permission[15]?.podcast?.read &&
                  <li>
                    <Link to="/#" className="has-arrow waves-effect">
                      <i className="mdi mdi-cast-audio" style={{ color: Utils.themeColor }}></i>
                      <span>{props.t("Podcast")}</span>
                    </Link>
                    <ul className={"sub-menu"}>
                      <li><Link to="/podcast">{props.t("Podcasts")}</Link></li>
                      <li><Link to="/podcast_request">{props.t("Podcast Request")}</Link></li>
                    </ul>
                  </li>
                }
                {/* {permission?.length && permission[18]?.social_media?.read &&
                  <li>
                    <Link to="/social-media" className="waves-effect">
                      <i className="mdi mdi-play-network" style={{ color: Utils.themeColor }}></i>
                      <span>{props.t("Social-Media")}</span>
                    </Link>
                  </li>
                } */}
                {/* {permission?.length && permission[19]?.settings?.read &&
                  <li>
                    <Link to="/setting" className="waves-effect">
                      <i className="mdi mdi-cogs" style={{ color: Utils.themeColor }}></i>
                      <span>{props.t("Settings")}</span>
                    </Link>
                  </li>
                } */}
                {permission?.length && permission[20]?.notification?.read &&
                  <li>
                    <Link to="/notification" className="waves-effect">
                      <i className="mdi mdi-bell" style={{ color: Utils.themeColor }}></i>
                      <span>{props.t("Notifications")}</span>
                    </Link>
                  </li>
                }
                {/* {permission?.length && permission[21]?.image_store?.read &&
                  <li>
                    <Link to="/store" className="waves-effect">
                      <i className="mdi mdi-image-multiple" style={{ color: Utils.themeColor }}></i>
                      <span>{props.t("Image Store")}</span>
                    </Link>
                  </li>
                } */}
                {permission?.length && permission[22]?.blog?.read &&
                  <li>
                    <Link to="/#" className="has-arrow waves-effect">
                      <i className="mdi mdi-account-star" style={{ color: Utils.themeColor }}></i>
                      <span>{props.t("Blog")}</span>
                    </Link>
                    <ul className={"sub-menu"}>
                      <li><Link to="/blog">{props.t("Blog")}</Link></li>
                      <li><Link to="/blog-category">{props.t("Category")}</Link></li>
                    </ul>
                  </li>
                }
              </>
            }

          </ul>
        </div>
      </SimpleBar>
    </React.Fragment >
  )
}

const mapStateToProps = state => {
  const { notis } = state.notification;
  const { user } = state.Profile;
  return { notis, user }
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  getNotiSuccess: PropTypes.func,
  loginSuccess: PropTypes.func
}

export default withRouter(withTranslation()(connect(mapStateToProps, { getNotiSuccess, loginSuccess })(SidebarContent)))