import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  CardTitle,
  CardSubtitle,
  Modal,
  ModalBody,
  Label,
} from "reactstrap"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import Breadcrumb from "../../../components/Common/Breadcrumb";
import Utils from '../../Utility';
import { AvField, AvForm } from "availity-reactstrap-validation";
import Loader from "../../../components/Loader";
import DeleteModal from './DeleteModal';
import DataTable from 'react-data-table-component';
import { get, post, put, upload } from '../../../helpers/api_helper'
import { toast } from 'react-toastify';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Multiselect from 'multiselect-react-dropdown'
import moment from 'moment'


const Category = props => {
  const { user } = props;
  const [isAddForm, setIsAddForm] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [reportModal, setReportModal] = useState(null);
  const [currentAudio, setCurrentAudio] = useState(null);
  const [cats, setCats] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedLang, setSelectedLang] = useState([]);
  const [recom1, setRecom1] = useState([{ title: "" }, { title: "" }, { title: "" }]);
  const [recom2, setRecom2] = useState([{ title: "" }, { title: "" }, { title: "" }]);
  const [recom3, setRecom3] = useState([{ title: "" }, { title: "" }, { title: "" }]);
  const [recom4, setRecom4] = useState([{ title: "" }, { title: "" }, { title: "" }]);
  const [recom5, setRecom5] = useState([{ title: "" }, { title: "" }, { title: "" }]);

  useEffect(() => {
    if (user?.token) {
      getData();
    }
  }, [user]);

  useEffect(() => {
    get('/tags', { token: user?.token })
      .then(res => {
        setTags(res?.data);
      })
  }, []);

  useEffect(() => {
    if (!isAddForm) {
      setCurrentData(null);
      setSelectedLang([]);
      setRecom1([{ title: "" }, { title: "" }, { title: "" }]);
      setRecom2([{ title: "" }, { title: "" }, { title: "" }]);
      setRecom3([{ title: "" }, { title: "" }, { title: "" }]);
      setRecom4([{ title: "" }, { title: "" }, { title: "" }]);
      setRecom5([{ title: "" }, { title: "" }, { title: "" }]);
    }
  }, [isAddForm]);

  const getData = () => {
    if (!loading) {
      setLoading(true);
      get("/know/answer", { token: user?.token })
        .then(res => {
          setLoading(false);
          if (res?.statusCode == 200) {
            setData(res?.data);
          } else
            toast.error(res?.error);
        })
        .catch(err => {
          toast.error('Something Went Wrong!');
          setLoading(false);
        });
    }
  }

  useEffect(() => {
    setColumns([
      {
        name: 'User',
        selector: row => row.user?.name,
      },
      {
        name: 'Category Attempt',
        cell: row => <>
          <span>{row?.test?.length}</span>
        </>,
        selector: row => row?.test?.length,
        // maxWidth: '100px'
      },
      {
        name: 'Created At',
        selector: row => moment(row?.createdAt).format("DD MMM, YYYY hh:mm a"),
        maxWidth: "180px"
      },
      {
        cell: (row) => <>
          <Button className="table_action_btn" onClick={() => { setCurrentData(row); setReportModal(true) }}
            title={"View"}
            style={{ backgroundColor: Utils.themeColor, border: 'none' }}><span className={"mdi mdi-eye"} style={{ color: Utils.textColor }}></span></Button>
          {/* <Button className="table_action_btn" onClick={() => handleUpdateProj(row)}
                title={"Edit"}
                style={{ backgroundColor: Utils.themeColor, border: 'none' }}><span className="mdi mdi-pencil" style={{ color: Utils.textColor }}></span></Button>
              <Button className="table_action_btn" onClick={() => handleDeleteProj(row)}
                title={"Delete"}
                style={{ backgroundColor: Utils.themeColor, border: 'none' }}><span className="mdi mdi-delete" style={{ color: Utils.textColor }}></span></Button> */}
        </>,
        name: 'Action',
        ignoreRowClick: true,
        maxWidth: '100px'
        // allowOverflow: true,
        // button: true,
      },
    ])
  }, [data]);

  const handleValidSubmit = async (e, v) => {
    // console.log('values', v);
    if (selectedTags?.length < 1) {
      toast.error("Please select atleast one tag!");
      return;
    }
    let tempTags = [];
    selectedTags.forEach(item => {
      tempTags.push(item?._id);
    });
    let body = {
      ...v,
      tags: tempTags,
      recom1,
      recom2,
      recom3,
      recom4,
      recom5,
      alterLang: selectedLang,
      token: user?.token
    }
    if (currentData) {
      setLoading(true);
      body = { ...body, catId: currentData?._id };
      put("/know/cat", body)
        .then((res) => {
          setLoading(false);
          if (res?.statusCode == 200) {
            toast.success(res?.message);
            getData();
            setIsAddForm(false);
          } else {
            toast.error(res?.error);
          }
        })
        .catch(err => {
          toast.error(err);
          setLoading(false);
        })
    } else {
      try {
        setLoading(true);
        post("/know/cat", body)
          .then((res) => {
            setLoading(false);
            if (res?.statusCode == 200) {
              toast.success(res?.message);
              getData();
              setIsAddForm(false);
            } else {
              toast.error(res?.error);
            }
          })
          .catch(err => {
            toast.error(err);
            setLoading(false);
          })
      } catch (error) {
        setLoading(false);
        console.log('error while adding category');
        toast.error("Something Went Wrong!");
      }
    }
  }

  const handleUpdateProj = (row) => {
    setCurrentData(row);
    let tempTags = [];
    tags?.forEach((item) => {
      row?.tags?.forEach((tag) => {
        if (item?._id == tag?._id)
          tempTags.push(item);
      })
    });
    setSelectedTags(tempTags);
    if (row?.alterLang?.length)
      setSelectedLang(row?.alterLang);
    if (row?.recom1?.length)
      setRecom1(row?.recom1);
    if (row?.recom2?.length)
      setRecom2(row?.recom2);
    if (row?.recom3?.length)
      setRecom3(row?.recom3);
    if (row?.recom4?.length)
      setRecom4(row?.recom4);
    if (row?.recom5?.length)
      setRecom5(row?.recom5);
    setIsAddForm(true);
  }

  const handleUpdateStatus = (row) => {
    if (user?.role == "Admin") {
      setCurrentData({ ...row, actionType: 'Status' });
      setDeleteModal(true);
    } else
      toast.error("You're not authorized for this action!");
  }

  const handleDeleteProj = (proj) => {
    setCurrentData(proj);
    setDeleteModal(true);
  }

  const onStatus = () => {
    // console.log('status', currentModalData);
    setLoading(true);
    let body = {
      catId: currentData?._id,
      isActive: !currentData?.isActive,
      token: user?.token
    }
    let url = `/know/cat`;
    put(url, body)
      .then(json => {
        console.log('response from delete user', json);
        setLoading(false);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getData();
          setDeleteModal(false);
          setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        setLoading(false);
        toast.error(error);
        console.error('error while deleting user', error);
      })
  }

  const onDelete = () => {
    setLoading(true);
    let body = {
      catId: currentData?._id,
      token: user?.token
    }
    let url = `/know/cat`;
    if (currentData?.actionType == 'Paid') {
      body = { ...body, isPaid: !currentData?.isPaid };
    } else {
      body = { ...body, isDeleted: true };
    }
    put(url, body)
      .then(json => {
        console.log('response from delete user', json);
        setLoading(false);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getData();
          setDeleteModal(false);
          setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        setLoading(false);
        toast.error(error);
        console.error('error while deleting user', error);
      })
  }

  const urltoFile = (url, filename, mimeType) => {
    return (fetch(url)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
  }

  const handleDragEnd = (result) => {
    if (!result.destination) return; // Item was dropped outside the list
    const items = [...data];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    let ids = [], position = [];
    items.forEach((item, index) => {
      ids.push(item?._id);
      position.push(index);
    })
    let body = {
      ids,
      position,
      token: user?.token
    }
    put("/know/cat/position", body)
      .then(res => {
        // if (res?.statusCode == 200)
        setData(items);
      })
  };

  const handlePaid = (row) => {
    setCurrentData({ ...row, actionType: "Paid" });
    setDeleteModal(true);
  }

  const handleSelectLang = (code, lang) => {
    if (code != "en") {
      let temp = Object.assign([], selectedLang);
      let already = temp.findIndex(x => x.code == code);
      if (already == -1) {
        temp.push({ code: code, title: "", lang });
      } else {
        temp.splice(already, 1);
      }
      // console.log("temp", temp);
      setSelectedLang(temp);
    }
  }

  const handleAlterTitle = (index, key, val) => {
    let temp = Object.assign([], selectedLang);
    temp[index][key] = val;
    setSelectedLang(temp);
  }
  console.log('image changed', currentData);

  const handleRecom = (val, index, getter, setter) => {
    let obj = Object.assign([], getter);
    obj[index].title = val;
    setter(obj);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Loader visible={loading} />
        {/* Render Breadcrumb */}
        <Breadcrumb title={Utils.projectName} breadcrumbItem="Know yourself Categories" />
        <Modal isOpen={reportModal} centered={true} style={{ maxWidth: 1000 }} toggle={() => setReportModal(false)}>
          <ModalBody className="py-3 px-5">
            <h5 className="text-black font-size-20">{`${currentData?.user?.name}'s Report`}</h5>
            <div className="p-2">
              {currentData?.test?.map((item, index) => (
                <div className='mt-2'>
                  <hr></hr>
                  <h5>{item?.catDetails.title}</h5>
                  <p>{item?.recom}</p>
                  {item?.answers?.map((ans, inde) => (
                    <div className="d-flex justify-content-between">
                      <div>
                        <p className="mb-1">Q.{inde + 1} {ans?.ques}</p>
                        <p className="mb-2" style={{ color: "blue" }}>Ans. {ans?.option} ({ans?.marks} marks)</p>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </ModalBody>
        </Modal>
        <Card>
          <CardBody>
            <Row>
              <DeleteModal
                show={deleteModal}
                onDeleteClick={() => currentData?.actionType == 'Status' ? onStatus() : onDelete()}
                onCloseClick={() => setDeleteModal(false)}
                actionType={currentData?.actionType}
                data={currentData}
              />
              <Col className="col-12">

                <div className="d-flex justify-content-between" style={{ marginLeft: 'auto', marginBottom: 30 }}>
                  <div>
                    <CardTitle className="h4">Knowyourself Answers</CardTitle>
                    <CardSubtitle className="mb-3">
                      All Users you have Attemptted the know yourself test.
                    </CardSubtitle>
                  </div>
                  <div className="group_btn_box">
                    {user?.role == 'Admin' ?
                      <div className="text-center" style={{ marginLeft: 'auto' }}>
                        <Button className='btn-text-btn' style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => setIsAddForm(true)}>
                          Add
                        </Button>
                      </div>
                      :
                      <div className="text-center" style={{ marginLeft: 'auto' }}>
                        {user?.permissions?.length && user?.permissions[7]?.knowyourself?.write &&
                          <Button style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => setIsAddForm(true)}>
                            Add
                          </Button>
                        }
                      </div>
                    }
                  </div>
                </div>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="table">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        <DataTable
                          columns={columns}
                          data={data}
                          pagination
                          paginationPerPage={100}
                          paginationRowsPerPageOptions={[100, 200, 300, 400]}
                          customStyles={{
                            headCells: {
                              style: {
                                color: 'black',
                                fontWeight: 'bold',
                                fontSize: 15,
                                maxWidth: 80
                              },
                            },
                            cells: {
                              style: {
                                maxWidth: 50
                              }
                            }
                          }}
                          conditionalRowStyles={[{
                            when: row => row?.seen?.length < 1,
                            style: { backgroundColor: user?.company?.theme ? user?.company?.theme : Utils.themeColor, color: 'white', cursor: 'pointer' },
                          }]}
                        // onRowClicked={(r, e) => seenNoti(r)}
                        />
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>

              </Col>
            </Row>
          </CardBody>
        </Card>
        <audio id="audio" src={currentAudio} />
      </div>
    </React.Fragment>
  )
}


const mapStatetoProps = state => {
  const { user } = state.Profile;
  return { user }
}

export default withRouter(
  connect(mapStatetoProps, {})(Category)
)