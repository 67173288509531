import React, { useEffect, useState } from "react";
// import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button, Modal, ModalBody, Label } from "reactstrap"
// import "./datatables.scss";
// import '../Icons/IconFontawesome';
import moment from "moment";
import Utils from '../Utility';
import { post, get, put, download } from "../../helpers/api_helper";
import DeleteModal from './DeleteModal';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { AvField, AvForm } from "availity-reactstrap-validation";

const Table = (props) => {
  const { user, role, currentData, from, setCurrentData, setLoading } = props;
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [currentAudio, setCurrentAudio] = useState(null);
  const [profileModal, setProfileModal] = useState(false);
  const [scheduleModal, setScheduleModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [reportModal, setReportModal] = useState(false);

  useEffect(() => {
    if (user) {
      getData();
    }
  }, [user]);

  const getData = () => {
    get("/talk/list", { token: user?.token })
      .then(json => {
        // console.log('response from get project list', json);
        if (json?.statusCode == 200) {
          json?.data?.map((item) => {
            item.isPlaying = false;
          });
          setData(json?.data);
        }
      })
      .catch(error => {
        console.log('error while getting project list', error);
      })
  }

  useEffect(() => {
    setColumns([
      {
        name: 'User',
        cell: (row) => <>
          <span>{row?.user[0]?.name}</span>
        </>,
      },
      {
        name: 'Schedule At',
        selector: row => row?.status == "Scheduled" ? moment(row?.scheduleAt).format("DD-MMM-YYYY hh:mm a") : "-",
        maxWidth: '170px'
      },
      {
        name: 'Created At',
        selector: row => moment(row?.createdAt).format("DD-MMM-YYYY hh:mm a"),
        maxWidth: '170px'
      },
      {
        cell: (row) => <>
          {row?.status == "Requested" &&
            <Button
              style={{ backgroundColor: '#cbcb02', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}>
              <span className="text-white" style={{}}>
                {row?.status}
              </span>
            </Button>
          }
          {row?.status == "Scheduled" &&
            <Button
              style={{ backgroundColor: 'blue', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}>
              <span className="text-white" style={{}}>
                {row?.status}
              </span>
            </Button>
          }
          {row?.status == "Rejected" &&
            <Button
              style={{ backgroundColor: 'red', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}>
              <span className="text-white" style={{}}>
                {row?.status}
              </span>
            </Button>
          }
          {row?.status == "Cancelled" &&
            <Button
              style={{ backgroundColor: 'red', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}>
              <span className="text-white" style={{}}>
                {row?.status}
              </span>
            </Button>
          }
          {row?.status == "Completed" &&
            <Button className="status_btn"
              style={{ backgroundColor: 'green', border: 'none' }}>
              <span className="text-white" style={{}}>
                {row?.status}
              </span>
            </Button>
          }
        </>,
        name: 'Status',
        ignoreRowClick: true,
        maxWidth: '140px'
      },
      {
        name: 'Created At',
        selector: row => moment(row?.createdAt).format("DD MMM, YYYY hh:mm a"),
        maxWidth: "180px"
      },
      {
        cell: (row) => <>
          <Button className="table_action_btn" onClick={() => { setCurrentData(row?.user[0]); setReportModal(true) }}
            title={"Test"}
            style={{ backgroundColor: Utils.themeColor, border: 'none' }}>
            {/* <span className={"mdi mdi-list-status"} style={{}}></span> */}
            <img src={require("../../assets/images/list-status.png")} className="table-icon" />
          </Button>
          <Button className="table_action_btn" onClick={() => { setCurrentData(row?.user[0]); setProfileModal(true) }}
            title={"View"}
            style={{ backgroundColor: Utils.themeColor, border: 'none' }}><span className={"mdi mdi-eye"} style={{}}></span></Button>
          {user?.role == "Admin" ?
            <>
              {row?.status != "Completed" && row?.status != "Rejected" && row?.status != "Cancelled" &&
                <Button className="table_action_btn" onClick={() => handleSchedule(row)}
                  title={"Schedule"}
                  style={{ backgroundColor: Utils.themeColor, border: 'none' }}><span className={"mdi mdi-clock"} style={{}}></span></Button>
              }
              {row?.status != "Completed" && row?.status != "Rejected" && row?.status != "Cancelled" &&
                <Button className="table_action_btn" onClick={() => handleReject(row)}
                  title={"Reject"}
                  style={{ backgroundColor: Utils.themeColor, border: 'none' }}><span className="mdi mdi-cancel" style={{}}></span></Button>
              }
              <Button className="table_action_btn" onClick={() => handleUserBan(row)}
                title={row?.user[0]?.canMeet ? "Ban User" : "Approve User"}
                style={{ backgroundColor: Utils.themeColor, border: 'none' }}><span className={row?.user[0]?.canMeet ? "mdi mdi-account-check" : "mdi mdi-account-off"} style={{}}></span></Button>
            </>
            :
            <>
              {user?.permissions?.length && user?.permissions[13]?.talk_to_me?.update &&
                <>
                  {row?.status != "Completed" && row?.status != "Rejected" && row?.status != "Cancelled" &&
                    <Button className="table_action_btn" onClick={() => handleSchedule(row)}
                      title={"Schedule"}
                      style={{ backgroundColor: Utils.themeColor, border: 'none' }}><span className={"mdi mdi-clock"} style={{}}></span></Button>
                  }
                  {row?.status != "Completed" && row?.status != "Rejected" && row?.status != "Cancelled" &&
                    <Button className="table_action_btn" onClick={() => handleReject(row)}
                      title={"Reject"}
                      style={{ backgroundColor: Utils.themeColor, border: 'none' }}><span className="mdi mdi-cancel" style={{}}></span></Button>
                  }
                </>
              }
              {user?.permissions?.length && user?.permissions[13]?.talk_to_me?.delete &&
                <Button className="table_action_btn" onClick={() => handleUserBan(row)}
                  title={row?.user[0]?.canMeet ? "Ban User" : "Approve User"}
                  style={{ backgroundColor: Utils.themeColor, border: 'none' }}><span className={row?.user[0]?.canMeet ? "mdi mdi-account-check" : "mdi mdi-account-off"} style={{}}></span></Button>
              }
            </>
          }
        </>,
        name: 'Action',
        ignoreRowClick: true,
        maxWidth: '190px'
        // allowOverflow: true,
        // button: true,
      },
    ])
  }, [data]);

  const handlePaid = (row) => {
    setCurrentData({ ...row, actionType: "Paid" });
    setDeleteModal(true);
  }

  const handleSchedule = (row) => {
    setCurrentData(row);
    setScheduleModal(true);
  }

  const handleReject = (row) => {
    setCurrentData(row);
    setRejectModal(true);
  }

  const handleUpdateProj = (proj) => {
    console.log('updating proj', proj);
    props?.setCurrentData(proj);
    props.add(true);
  }

  const handleUpdateStatus = (item) => {
    console.log('updating proj', item);
    setCurrentData({ ...item, actionType: 'Status' });
    setDeleteModal(true);
  }

  const handleDeleteProj = (proj) => {
    console.log('updating proj', proj);
    props?.setCurrentData({ ...proj, actionType: 'Delete' });
    setDeleteModal(true);
  }

  const onDelete = () => {
    console.log('deleted', currentData);
    let body = {
      userId: currentData?.user[0]?._id,
      canMeet: currentData?.user[0]?.canMeet ? false : true,
      token: user?.token
    }
    let url = "/user";
    put(url, body)
      .then(json => {
        console.log('response from on click action', json);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getData();
          setDeleteModal(false);
          props?.setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        console.error('error while deleting user', error);
      })
  }

  const handleDragEnd = (result) => {
    console.log('dragging item', result);
    // Handle the drag-and-drop logic here
    if (!result.destination) return; // Item was dropped outside the list
    const items = [...data];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    let ids = [], position = [];
    items.forEach((item, index) => {
      ids.push(item?._id);
      position.push(index);
    })
    let body = {
      ids,
      position,
      token: user?.token
    }
    put("/talk/position", body)
      .then(res => {
        // if (res?.statusCode == 200)
        setData(items);
      })
  };

  const updateSchedule = (e, v) => {
    console.log("scheduling ", v);
    let body = {
      talkId: currentData?._id,
      url: v?.url,
      scheduleAt: v?.scheduleAt,
      desc: v?.desc,
      status: "Scheduled",
      token: user?.token
    }
    put("/talk", body)
      .then(json => {
        console.log('response from on click action', json);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getData();
          setScheduleModal(false);
          setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        console.error('error while deleting user', error);
      })
  }

  const formatDateTime = (str) => {
    console.log("formatting date", str);
    return moment(str).format('YYYY-MM-DDTHH:mm');
    // return "2023-11-29T07:50";
  }

  const updateReject = (e, v) => {
    console.log("rejecting ", v);
    let body = {
      talkId: currentData?._id,
      desc: v?.desc,
      status: "Rejected",
      token: user?.token
    }
    put("/talk", body)
      .then(json => {
        console.log('response from on click action', json);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getData();
          setRejectModal(false);
          setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        console.error('error while deleting user', error);
      })
  }

  const handleUserBan = (row) => {
    setCurrentData({ ...row, actionType: "Status" });
    setDeleteModal(true);
  }

  console.log("current data", currentData);

  return (
    <React.Fragment>
      <div className="">
        <Row>
          <DeleteModal
            show={deleteModal}
            onDeleteClick={() => onDelete()}
            onCloseClick={() => setDeleteModal(false)}
            data={currentData}
          />
          <Modal isOpen={reportModal} centered className="px-2" toggle={() => setReportModal(false)}>
            <ModalBody>
              <Label><h4>User Profile</h4></Label>
              <Row>
                <Col md={3}>
                  <div>
                    <img src={currentData?.profile_picture} style={{ width: 80, height: 80, borderRadius: 40 }} />
                  </div>
                </Col>
                <Col md={9} className="mt-2">
                  <h4>{currentData?.name}</h4>
                  <p>{currentData?.email}</p>
                </Col>
              </Row>
              {currentData?.talks?.length && currentData?.talks[0]?.answers?.length ?
                <Row className="mt-4">
                  <h5>User Questionare Report</h5><p className="mb-0">{moment(currentData?.talks[0]?.createdAt).format("DD-MMM-YYYY hh:mm a ")}</p>
                  {currentData?.talks[0]?.answers?.map((item, index) => (
                    <div>
                      <hr></hr>
                      <div className="d-flex justify-content-between">
                        <div>
                          <p className="mb-0">{item?.ques}</p>
                          <p className="mb-0" style={{ color: "blue" }}>{item?.option}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </Row>
                : null}
            </ModalBody>
          </Modal>
          <Modal isOpen={profileModal} centered className="px-2" toggle={() => setProfileModal(false)}>
            <ModalBody>
              <Label><h4>User Profile</h4></Label>
              <Row>
                <Col md={3}>
                  <div>
                    <img src={currentData?.profile_picture} style={{ width: 80, height: 80, borderRadius: 40 }} />
                  </div>
                </Col>
                <Col md={9} className="mt-2">
                  <h4>{currentData?.name}</h4>
                  <p>{currentData?.email}</p>
                </Col>
              </Row>
              <Row className="mt-4">
                <h5>Previous Sessions</h5>
                {currentData?.talks?.map((item, index) => (
                  <div>
                    <hr></hr>
                    <div className="d-flex justify-content-between">
                      <div>
                        <p className="mb-0">{moment(item?.createdAt).format("DD-MMM-YYYY hh:mm a ")}</p>
                        {item?.status == "Rejected" &&
                          <p className="mb-0" style={{ color: "red" }}>{item?.desc}</p>
                        }
                        {item?.status == "Cancelled" &&
                          <p className="mb-0" style={{ color: "red" }}>{item?.desc}</p>
                        }
                        {item?.status == "Scheduled" &&
                          <p className="mb-0" style={{ color: "blue" }}>{moment(item?.scheduleAt).format("DD-MMM-YYYY hh:mm a ")}</p>
                        }
                      </div>
                      {item?.status == "Completed" &&
                        <p className="mb-0" style={{ color: "green" }}>{item?.status}</p>
                      }
                      {item?.status == "Requested" &&
                        <p className="mb-0" style={{ color: "#cbcb02" }}>{item?.status}</p>
                      }
                      {item?.status == "Rejected" &&
                        <p className="mb-0" style={{ color: "red" }}>{item?.status}</p>
                      }
                      {item?.status == "Cancelled" &&
                        <p className="mb-0" style={{ color: "red" }}>{item?.status}</p>
                      }
                      {item?.status == "Scheduled" &&
                        <p className="mb-0" style={{ color: "blue" }}>{item?.status}</p>
                      }
                    </div>
                  </div>
                ))}
              </Row>
            </ModalBody>
          </Modal>
          <Modal isOpen={scheduleModal} centered className="px-2" toggle={() => setScheduleModal(false)}>
            <ModalBody>
              <Label><h4>Schedule {role}</h4></Label>
              <Row>
                <AvForm onValidSubmit={updateSchedule}>
                  <Col md={12}>
                    <AvField
                      name="url"
                      placeholder="Enter meeting url"
                      type="url"
                      label="Meeting Url"
                      value={currentData?.url}
                      required
                    />
                  </Col>
                  <Col md={12} className="mt-2">
                    <AvField
                      name="scheduleAt"
                      placeholder="Enter meeting url"
                      type="datetime-local"
                      label="Schedule Time"
                      value={formatDateTime(currentData?.scheduleAt)}
                      required
                    />
                  </Col>
                  <Col md={12} className="mt-2">
                    <AvField
                      name="desc"
                      placeholder="Enter message"
                      type="textarea"
                      label="Message"
                      rows={4}
                      required
                    />
                  </Col>
                  <Col lg={12} md={12}>
                    <div className="text-center mt-4">
                      <Button type="reset" style={{ border: 'none', backgroundColor: Utils.themeColor }} onClick={() => { setCurrentData(null); setScheduleModal(false) }}>
                        Cancel
                      </Button>
                      <Button type="submit" style={{ marginLeft: 10, border: 'none', backgroundColor: Utils.themeColor }}>
                        Update {role}
                      </Button>
                    </div>
                  </Col>
                </AvForm>
              </Row>
            </ModalBody>
          </Modal>
          <Modal isOpen={rejectModal} centered className="px-2" toggle={() => setRejectModal(false)}>
            <ModalBody>
              <Label><h4>Reject {role}</h4></Label>
              <Row>
                <AvForm onValidSubmit={updateReject}>
                  <Col md={12}>
                    <AvField
                      name="desc"
                      placeholder="Enter reason for rejection"
                      type="textarea"
                      label="Reason"
                      rows={4}
                      required
                    />
                  </Col>
                  <Col lg={12} md={12}>
                    <div className="text-center mt-4">
                      <Button type="reset" style={{ border: 'none', backgroundColor: Utils.themeColor }} onClick={() => { setCurrentData(null); setRejectModal(false) }}>
                        Cancel
                      </Button>
                      <Button type="submit" style={{ marginLeft: 10, border: 'none', backgroundColor: Utils.themeColor }}>
                        Update {role}
                      </Button>
                    </div>
                  </Col>
                </AvForm>
              </Row>
            </ModalBody>
          </Modal>
          <Col className="col-12">
            <Card>
              <CardBody>
                <div className="d-flex" style={{ marginLeft: 'auto', marginBottom: 30 }}>
                  <div>
                    <CardTitle className="h4">{props.role}s</CardTitle>
                    <CardSubtitle className="mb-3">
                      {role} you have added before,<code> Anybody</code> can <code>check</code> and <code>visit</code> on these. if <code>Status</code> is Active.
                    </CardSubtitle>
                  </div>
                  {/* <div className="text-center" style={{ marginLeft: 'auto' }}>
                    <Button style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => props?.add(true)}>
                      Add
                    </Button>
                  </div> */}
                </div>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="table">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        <DataTable
                          columns={columns}
                          data={data}
                          pagination
                          paginationPerPage={100}
                          paginationRowsPerPageOptions={[100, 200, 300, 400]}
                          conditionalRowStyles={[{
                            when: row => row?.style,
                            style: row => ({ width: row?.style?.width }),
                          },
                          ]}
                          customStyles={{
                            headCells: {
                              style: {
                                color: 'black',
                                fontWeight: 'bold',
                                fontSize: 15,
                                width: 0
                              },
                            },
                            cells: {
                              style: {
                                width: 0
                              }
                            }
                          }}
                        />
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </CardBody>
            </Card>
            {/* <audio id="audio" src={currentAudio} /> */}
          </Col>
        </Row>
      </div>

    </React.Fragment >
  )
}

export default Table;
